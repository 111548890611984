import { http } from "utilities/http";
import {
  StorageResponse,
  Storage,
  CreateStorageRequest,
  UpdateStorageRequest,
} from "types/storage";
import { STORAGES, STORAGE } from "constants/paths";
import qs from "qs";
import { PaginationFilter } from "types/common";

interface GetStoragesFilter extends PaginationFilter {}

export const getStorages = async (filters?: GetStoragesFilter) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return await http<StorageResponse>(`${STORAGES}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getStorage = async (id: string) => {
  return await http<Storage>(STORAGE(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const createStorage = (storage: CreateStorageRequest) =>
  http<Storage>(STORAGES, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(storage),
  });

export const updateStorage = (storage: UpdateStorageRequest, id: string) =>
  http<Storage>(STORAGE(id), {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(storage),
  });

export const deleteStorage = (id: string) =>
  http<Storage>(STORAGE(id), {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
