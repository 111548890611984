const AddIcon = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 9C1 10.0506 1.20693 11.0909 1.60896 12.0615C2.011 13.0321 2.60028 13.914 3.34315 14.6569C4.08601 15.3997 4.96793 15.989 5.93853 16.391C6.90914 16.7931 7.94943 17 9 17C10.0506 17 11.0909 16.7931 12.0615 16.391C13.0321 15.989 13.914 15.3997 14.6569 14.6569C15.3997 13.914 15.989 13.0321 16.391 12.0615C16.7931 11.0909 17 10.0506 17 9C17 7.94943 16.7931 6.90914 16.391 5.93853C15.989 4.96793 15.3997 4.08601 14.6569 3.34315C13.914 2.60028 13.0321 2.011 12.0615 1.60896C11.0909 1.20693 10.0506 1 9 1C7.94943 1 6.90914 1.20693 5.93853 1.60896C4.96793 2.011 4.08601 2.60028 3.34315 3.34315C2.60028 4.08601 2.011 4.96793 1.60896 5.93853C1.20693 6.90914 1 7.94943 1 9Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 9H12"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 6V12"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddIcon;
