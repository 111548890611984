import React from "react";
import styled from "styled-components";
import { P } from "components/Typography/Typography";
import { Flex } from "components";
import { Apartment, Reservation } from "types/apartment";
import { Grid } from "../ReservationsView.styled";
import { ReactComponent as DetailsIcon } from "assets/icons/ico-vert-dots.svg";
import { theme } from "styles";
import { format } from "date-fns";

const Tile = styled(Grid)`
  padding: 24px 32px;
  min-height: 100px;
  grid-template-columns: repeat(4, 1fr) 20px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

const Field = styled.div`
  display: flex;
  border-right: 1.5px solid ${({ theme }) => theme.palette.neutral.anotherGrey};
  min-height: 52px;
  align-items: center;
  margin-right: 24px;
  padding-right: 24px;
  &:nth-last-child(2) {
    border-right: none;
  }
`;

interface ReservationsTileProps {
  apartment: Apartment;
  lastReservation?: Reservation;
  futureReservation?: Reservation;
  onDetailsClick: (id: string) => void;
}

const ReservationsTile: React.FC<ReservationsTileProps> = ({
  apartment,
  lastReservation,
  futureReservation,
  onDetailsClick,
}) => {
  const handleDetailsClick = (id: string) => {
    onDetailsClick(id);
  };

  return (
    <Tile>
      <Field>
        <P variant="body2">{apartment.short_name}</P>
      </Field>
      <Field>
        <P variant="body2">
          {lastReservation
            ? format(new Date(lastReservation.date_to), "dd.MM.yyyy, HH:mm")
            : "Brak"}
        </P>
      </Field>
      <Field>
        <P variant="body2">
          {futureReservation
            ? format(new Date(futureReservation.date_from), "dd.MM.yyyy, HH:mm")
            : "Brak"}
        </P>
      </Field>
      <Field>
        <P variant="body2">
          {apartment?.lock?.passcode ? apartment?.lock?.passcode : "Brak"}
        </P>
      </Field>
      <Flex>
        <DetailsIcon
          style={{
            cursor: "pointer",
            marginLeft: "auto",
            fill: theme.palette.neutral.anotherGrey,
            width: "14px",
          }}
          onClick={() => handleDetailsClick(apartment.id.toString())}
        />
      </Flex>
    </Tile>
  );
};

export default ReservationsTile;
