const DeleteIcon = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 4.55566H15"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.875 4.55566L2.75 15.2223C2.75 15.6938 2.93437 16.146 3.26256 16.4794C3.59075 16.8128 4.03587 17.0001 4.5 17.0001H11.5C11.9641 17.0001 12.4092 16.8128 12.7374 16.4794C13.0656 16.146 13.25 15.6938 13.25 15.2223L14.125 4.55566"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.375 4.55556V1.88889C5.375 1.65314 5.46719 1.42705 5.63128 1.26035C5.79538 1.09365 6.01794 1 6.25 1H9.75C9.98206 1 10.2046 1.09365 10.3687 1.26035C10.5328 1.42705 10.625 1.65314 10.625 1.88889V4.55556"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeleteIcon;
