import { FC, useState } from "react";
import { Box, Flex, Link, Spinner, Typography } from "components";
import { Button, TextField } from "components/_form";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "validation/authSchemas";
import { ILogin } from "types/forms/auth";
import { login } from "../api/authApi";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { H3 } from "components/Typography/Typography";

const Wrapper = styled(Flex)`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const defaultValues = {
  email: "",
  password: "",
};

export const LoginView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
    reset,
  } = useForm<ILogin>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setError("");
    setIsLoading(true);

    try {
      const response = await login(data);
      if (!response) return;
      localStorage.setItem("currentUser", JSON.stringify(response.data));
      navigate("/app/tasks");
    } catch (err: any) {
      if (err && err.message) {
        toast.error(err.message);
        setError(err.message);
        reset(defaultValues);
      }
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Wrapper
      justifyContent="center"
      flexWrap="wrap"
      flexDirection="column"
      height="100%"
      gap="30px"
    >
      <H3 variant="h3" textAlign="center">
        Logowanie
      </H3>
      <Form onSubmit={onSubmit}>
        <Flex flexDirection="column" width="100%">
          <TextField
            label="Email:"
            {...register("email")}
            error={t(errors.email?.message || "")}
          />
          <TextField
            type="password"
            label={`${t("password")}:`}
            {...register("password")}
            error={t(errors.password?.message || "")}
          />
        </Flex>
        <Link to="/password-reset">{t("forgotPass")}</Link>

        <Box mt="15px">
          {isLoading ? (
            <Spinner />
          ) : (
            <Button
              fullWidth
              type="submit"
              disabled={!formState.isValid}
              label={t("buttons.login")}
            />
          )}
        </Box>
      </Form>
      <Flex alignItems="center" justifyContent="center" gap="5px">
        <Typography.P variant="body2">{t("accountless")}</Typography.P>
        <Link to="/register">{t("register")}</Link>
      </Flex>
    </Wrapper>
  );
};
