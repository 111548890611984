import React from "react";
import { Flex, Legend, Line } from "components";
import {
  SidebarMenu,
  TSidebarMenuItem,
} from "components/SidebarMenu/SidebarMenu";
import CalendarIcon from "components/icons/CalendarIcon";
import TasksIcon from "components/icons/TasksIcon";
import UsersIcon from "components/icons/UsersIcon";

const items: TSidebarMenuItem[] = [
  { path: "/app/tasks", label: "Zadania", icon: <TasksIcon /> },
  { path: "/app/reservations", label: "Rezerwacje", icon: <CalendarIcon /> },
  { path: "/app/clients", label: "Klienci", icon: <UsersIcon /> },
];

export const SideBar = () => {
  return (
    <Flex alignItems="center" flexDirection="column" gap="20px">
      <SidebarMenu items={items} />
      <Line />
      <Legend />
    </Flex>
  );
};
