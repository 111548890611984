import { Flex } from "components";
import { P } from "components/Typography/Typography";
import { API_PHOTO_URL } from "constants/paths";
import ImageViewer from "awesome-image-viewer";
import styled from "styled-components";

interface IImagesProps {
  images: string[];
}

export const ImageTile = styled.div<{ src: string }>`
  width: 85px;
  height: 56px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  background-color: ${({ theme }) => theme.palette.neutral.light};
  border-radius: 5px;
`;

const Images = ({ images }: IImagesProps) => {
  const parsedImages = images.map((e) => {
    return API_PHOTO_URL + e;
  });

  const handleViewer = () => {
    let viewerImages: { mainUrl: string }[] = [];

    parsedImages.map((e) => viewerImages.push({ mainUrl: e }));

    new ImageViewer({ images: viewerImages });
  };

  return (
    <>
      {!!parsedImages.length ? (
        <Flex
          alignItems="center"
          gap="5px"
          onClick={handleViewer}
          style={{ cursor: "pointer" }}
        >
          <ImageTile src={parsedImages[0]} />

          {parsedImages.length - 1 > 0 && `+${parsedImages.length - 1}`}
        </Flex>
      ) : (
        <P variant="body2">Brak</P>
      )}
    </>
  );
};

export default Images;
