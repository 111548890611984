import { http } from "utilities/http";
import {
  ActivityResponse,
  SingleActivityResponse,
  GetActivitiesFilter,
} from "types/activity";
import qs from "qs";
import { ACTIVITIES, ACTIVITY } from "constants/paths";

export const getActivities = async (filters: GetActivitiesFilter) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return await http<ActivityResponse>(`${ACTIVITIES}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getActivity = async (id: string) => {
  return await http<SingleActivityResponse>(ACTIVITY(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
