import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Flex, Modal } from "components";
import {
  ErrorText,
  FieldLabel as Label,
  TimePicker,
  Button,
  Radio,
} from "components/_form";
import { Select, IOption } from "components/_form/Select/Select";
import { CustomDatePicker } from "./CustomDatePicker";
import { H3 } from "components/Typography/Typography";
import { getActivities } from "services/activities";
import { Activity } from "types/activity";
import { getApartments } from "services/apartments";
import { Apartment } from "types/apartment";
import { Controller } from "react-hook-form";
import { User } from "types/user";
import { getUsers } from "services/users";
import { Grid, SeparatorStyled } from "./TaskDetailsModal";
import AddIcon from "components/icons/AddIcon";
import EditIcon from "components/icons/EditIcon";
import {
  add,
  endOfDay,
  isAfter,
  isEqual,
  isSameDay,
  startOfDay,
  sub,
} from "date-fns";
import LastAndComingReservations from "./LastAndComingReservations";
import { CreateTaskRequest, Task, UpdateTaskRequest } from "types/task";
import { Status, status } from "components/Legend/Legend";

interface FormValues {
  user_id: number;
  apartment_id: number;
  planned_date_from: Date;
  planned_date_to: Date;
  activity_id: number;
  status: string;
}

function taskToFormValues(task: Task): FormValues {
  return {
    user_id: task.user.id,
    apartment_id: task.apartment.id,
    planned_date_from: new Date(task.planned_date_from),
    planned_date_to: new Date(task.planned_date_to),
    activity_id: task.activity.id,
    status: task.status,
  };
}

interface CreateTaskModalProps {
  isOpen: boolean;
  onCancelClick: () => void;
  onSubmit?: (taskData: CreateTaskRequest) => void;
  onUpdate?: (taskData: UpdateTaskRequest, taskId: string) => void;
  editMode?: boolean;
  task?: Task;
}

const CreateEditTaskModal: React.FC<CreateTaskModalProps> = ({
  isOpen,
  onCancelClick,
  onSubmit,
  onUpdate,
  editMode,
  task,
}) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [apartments, setApartments] = useState<Apartment[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const today = new Date();
  const initialValues = task ? taskToFormValues(task) : {};
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
    trigger,
    watch,
    register,
  } = useForm<FormValues>({
    defaultValues: { ...(editMode ? initialValues : { status: "dirty" }) },
  });

  const fromDate = watch("planned_date_from");
  const toDate = watch("planned_date_to");
  const apartmentId = watch("apartment_id");

  const [plannedFromTime, setPlannedFromTime] = useState<Date | null>(fromDate);
  const [plannedToTime, setPlannedToTime] = useState<Date | null>(toDate);

  useEffect(() => {
    const fetchApartments = async () => {
      try {
        const response = await getApartments({});
        setApartments(response.data);
      } catch (error) {
        console.error("Error fetching apartments:", error);
      }
    };

    fetchApartments();
  }, []);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await getActivities({});
        setActivities(response.data);
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };

    fetchActivities();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getUsers({});
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };

    fetchUsers();
  }, []);

  const apartmentOptions: IOption[] = apartments.map((apartment) => ({
    label: apartment.short_name,
    value: apartment.id.toString(),
  }));

  const activityOptions: IOption[] = activities.map((activity) => ({
    label: activity.name,
    value: activity.id.toString(),
  }));

  const usersOptions: IOption[] = users.map((user) => ({
    label: user?.profile?.first_name + " " + user?.profile?.last_name,
    value: user.id.toString(),
  }));

  const onSubmitAndClose = async (taskData: FormValues) => {
    if (onSubmit) await onSubmit({ task: taskData });
    reset();
    onCancelClick();
  };

  const onUpdateAndClose = async (taskData: FormValues) => {
    if (onUpdate) await onUpdate({ task: taskData }, String(task?.id ?? ""));
    onCancelClick();
  };

  const onCancelClickAndReset = () => {
    reset(initialValues);
    onCancelClick();
  };

  const form = (
    <form
      onSubmit={handleSubmit(editMode ? onUpdateAndClose : onSubmitAndClose)}
    >
      <Flex flexDirection="column">
        <H3 variant="h3">
          {editMode ? "Edytuj zadanie" : " Dodaj nowe zadanie"}
        </H3>
        <SeparatorStyled />
        <Controller
          control={control}
          name="activity_id"
          rules={{ required: "Rodzaj jest wymagany" }}
          render={({ field: { value, onChange } }) => {
            const selectedActivityOption = activityOptions.find((option) =>
              value ? option.value === value.toString() : false,
            );

            return (
              <Select
                label="Rodzaj"
                options={activityOptions}
                isMulti={false}
                selectedOptions={selectedActivityOption}
                onChange={(val: IOption) => onChange(parseInt(val.value))}
                error={errors.apartment_id?.message}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="apartment_id"
          rules={{ required: "Miejsce noclegowe jest wymagane" }}
          render={({ field: { value, onChange } }) => {
            const selectedApartmentOption = apartmentOptions.find((option) =>
              value ? option.value === value.toString() : false,
            );
            return (
              <Select
                label="Miejsce noclegowe"
                options={apartmentOptions}
                isMulti={false}
                selectedOptions={selectedApartmentOption}
                onChange={(val: IOption) => onChange(parseInt(val.value))}
                error={errors.apartment_id?.message}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="user_id"
          rules={{ required: "Pracownik jest wymagany" }}
          render={({ field: { value, onChange } }) => {
            const selectedUsersOption = usersOptions.find((option) =>
              value ? option.value === value.toString() : false,
            );
            return (
              <Select
                label="Pracownik"
                options={usersOptions}
                isMulti={false}
                selectedOptions={selectedUsersOption}
                onChange={(val: IOption) => onChange(parseInt(val.value))}
                error={errors.user_id?.message}
              />
            );
          }}
        />
        <Flex flexDirection="column" gap="5px" pb="10px">
          <Label>Status:</Label>
          {status.map((element) => (
            <Radio
              {...register("status")}
              key={element.value}
              value={element.value}
              onClick={(e) => {
                setValue("status", e.target.value);
              }}
              label={
                <Flex gap="5px" alignItems="center">
                  {element.label} <Status value={element.value} small />
                </Flex>
              }
            />
          ))}
        </Flex>
        <Grid>
          <Controller
            control={control}
            name="planned_date_from"
            rules={{ required: "Data rozpoczęcia jest wymagana" }}
            render={({ field: { value } }) => (
              <Flex flexDirection="column" width="100%">
                <Label error={errors.planned_date_from?.message}>
                  Data rozpoczęcia zadania:
                </Label>
                <Flex gap="5px">
                  <CustomDatePicker
                    value={value}
                    onChange={(date) => {
                      const newDate = new Date(date);
                      if (plannedFromTime) {
                        newDate.setHours(plannedFromTime.getHours());
                        newDate.setMinutes(plannedFromTime.getMinutes());
                      }
                      setValue(
                        "planned_date_from",
                        isEqual(newDate, toDate)
                          ? sub(newDate, { minutes: 30 })
                          : newDate,
                      );
                      trigger("planned_date_from");
                    }}
                    maxDate={toDate}
                  />
                  <TimePicker
                    selected={fromDate}
                    onChange={(time) => {
                      setPlannedFromTime(time);
                      if (value && time) {
                        const newDate = new Date(value);
                        newDate.setHours(time.getHours());
                        newDate.setMinutes(time.getMinutes());
                        setValue("planned_date_from", newDate);
                        trigger("planned_date_from");
                      }
                    }}
                    minTime={startOfDay(today)}
                    maxTime={
                      isSameDay(fromDate, toDate)
                        ? sub(toDate, { minutes: 30 })
                        : endOfDay(today)
                    }
                    hideTime={{ from: [0, 0], to: [0, 0] }}
                  />
                </Flex>
                <ErrorText show={!!errors.planned_date_from?.message}>
                  {errors.planned_date_from?.message}
                </ErrorText>
              </Flex>
            )}
          />

          <Controller
            control={control}
            name="planned_date_to"
            rules={{ required: "Data zakończenia jest wymagana" }}
            render={({ field: { value } }) => (
              <Flex flexDirection="column" width="100%">
                <Label error={errors.planned_date_to?.message}>
                  Data zakończenia zadania:
                </Label>
                <Flex gap="5px">
                  <CustomDatePicker
                    value={value}
                    onChange={(date) => {
                      const newDate = new Date(date);
                      if (plannedToTime) {
                        newDate.setHours(plannedToTime.getHours());
                        newDate.setMinutes(plannedToTime.getMinutes());
                      }
                      setValue(
                        "planned_date_to",
                        isEqual(newDate, fromDate)
                          ? add(newDate, { minutes: 30 })
                          : newDate,
                      );
                      trigger("planned_date_to");
                    }}
                    minDate={fromDate}
                  />
                  <TimePicker
                    selected={toDate}
                    onChange={(time) => {
                      setPlannedToTime(time);
                      if (value && time) {
                        const newDate = new Date(value);
                        newDate.setHours(time.getHours());
                        newDate.setMinutes(time.getMinutes());
                        setValue("planned_date_to", newDate);
                        trigger("planned_date_to");
                      }
                    }}
                    minTime={
                      isSameDay(fromDate, toDate)
                        ? add(fromDate, { minutes: 30 })
                        : startOfDay(today)
                    }
                    maxTime={endOfDay(today)}
                    hideTime={{ from: [0, 0], to: [0, 0] }}
                  />
                </Flex>
                <ErrorText show={!!errors.planned_date_to?.message}>
                  {errors.planned_date_to?.message}
                </ErrorText>
              </Flex>
            )}
          />
        </Grid>
        <LastAndComingReservations apartmentId={apartmentId} />
        <SeparatorStyled />
        <Flex justifyContent="space-between" width="100%">
          <Button
            variant="secondary"
            bordered
            label="Anuluj"
            onClick={onCancelClickAndReset}
          />
          <Button
            type="submit"
            variant="primary"
            label={editMode ? "Zapisz" : " Dodaj"}
            icon={editMode ? <EditIcon /> : <AddIcon />}
          />
        </Flex>
      </Flex>
    </form>
  );

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClickAndReset}
      disableBackdropClick
    >
      {form}
    </Modal>
  );
};

export default CreateEditTaskModal;
