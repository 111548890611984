export const API_URL = process.env.REACT_APP_API_URL;
export const API_PHOTO_URL = process.env.REACT_APP_BLOB_URL;

export const ME = `${API_URL}/users/me`;
export const USERS = `${API_URL}/users`;
export const USER = (id: string | number) => `${USERS}/${id}`;
export const PROFILE = `${API_URL}/profile`;

export const SCHEDULES = `${API_URL}/schedules`;
export const SCHEDULE = (id: string | number) => `${SCHEDULES}/${id}`;

export const TASKS = `${API_URL}/tasks`;
export const TASK = (id: string | number) => `${TASKS}/${id}`;

export const CLIENTS = `${API_URL}/clients`;
export const CLIENTS_D = `${API_URL}/clients_distinct`;
export const APARTMENTS = `${API_URL}/apartments`;
export const RESERVATIONS = `${API_URL}/reservations`;
export const LOCKS = `${API_URL}/locks`;

export const ACTIVITIES = `${API_URL}/activities`;
export const ACTIVITY = (id: string) => `${ACTIVITIES}/${id}`;

export const STORAGES = `${API_URL}/storages`;
export const STORAGE = (id: string) => `${STORAGES}/${id}`;

export const LOCATIONS = `${API_URL}/locations`;
