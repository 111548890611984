const EditIcon = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 14.3687H5.17522L16.1352 5.21112C16.6888 4.7485 16.9999 4.12106 16.9999 3.46682C16.9999 2.81258 16.6888 2.18513 16.1352 1.72251C15.5815 1.2599 14.8306 1 14.0476 1C13.2646 1 12.5136 1.2599 11.96 1.72251L1 10.8801V14.3687Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.498 14.5459H16.8472"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2021 11.5039H17.0001"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
