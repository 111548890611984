import React from "react";
import styled from "styled-components";
import { Box, Card } from "components";
import { Flex } from "components/Flex";
import { P } from "components/Typography/Typography";
import { useDropdown } from "hooks/useDropdown";
import { ReactComponent as PreviousIcon } from "assets/icons/ico-previous.svg";
import { rgba } from "utilities/rgba";
import ArrowUp from "components/icons/ArrowUp";
import ArrowDown from "components/icons/ArrowDown";

export interface IDropdown {
  name: string;
  items: Array<{
    label: string;
    onClick: () => void;
  }>;
  children?: React.ReactNode;
}

const Root = styled.div`
  position: relative;
`;

const WrapperChildren = styled(Flex)`
  cursor: pointer;
  gap: 10px;
`;

const WrapperDropdown = styled.div`
  right: 0;
  position: absolute;
  top: 100%;
  margin-top: 20px;
  min-width: 180px;
  z-index: 1000;
  background-color: ${({ theme }) => theme.palette.neutral.white};
  box-shadow: 0 0 25px -5px ${({ theme }) => theme.palette.neutral.medium};
  border-radius: 10px;
`;

const StyledTypography = styled(P)`
  ${({ theme }) => `
    padding: 12px 0px;
    white-space: nowrap;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    &:hover {
      background-color: ${rgba(theme.palette.primary.main, 0.2)};
    }
  `}
`;

export const Dropdown: React.FC<IDropdown> = ({ items, children, name }) => {
  const { buttonRef, optionsRef, isVisible, setIsVisible } = useDropdown(false);

  const handleClick = (onClick: () => any) => () => {
    onClick();
    setIsVisible(false);
  };

  return (
    <Root>
      <WrapperChildren
        ref={buttonRef}
        role="button"
        aria-hidden="true"
        alignItems="center"
      >
        <P variant="body2">{name}</P>
        {isVisible ? <ArrowUp /> : <ArrowDown />}
        {children}
      </WrapperChildren>

      {isVisible && (
        <WrapperDropdown ref={optionsRef}>
          {items.map(({ label, onClick }) => (
            <div
              key={label}
              onClick={handleClick(onClick)}
              aria-hidden="true"
              role="button"
            >
              <StyledTypography variant="body2">{label}</StyledTypography>
            </div>
          ))}
        </WrapperDropdown>
      )}
    </Root>
  );
};
