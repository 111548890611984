import { FC, useState, useEffect } from "react";
import { Box, Flex, Link, Spinner } from "components";
import { Button, Checkbox, TextField } from "components/_form";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSchema } from "validation/authSchemas";
import { IRegister } from "types/forms/auth";
import { register as registerRec } from "../api/authApi";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Flatten } from "types/helper-types";
import { getRegulations } from "api/regulations";
import { IRegulation } from "types/regulations";
import { H3, P } from "components/Typography/Typography";

const Wrapper = styled(Flex)`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;
export const RegisterView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [regulations, setRegulations] = useState<IRegulation[]>();
  const [acceptedCommonRegulations, setAcceptedCommonRegulations] = useState<
    number[]
  >([]);

  const loadRegulations = async () => {
    const { data } = await getRegulations();
    setRegulations(data);
  };

  useEffect(() => {
    loadRegulations();
  }, []);

  const {
    register,
    handleSubmit,
    formState,
    control,
    formState: { errors },
  } = useForm<Flatten<IRegister>>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      password_confirmation: "",
      first_name: "",
      last_name: "",
    },
    resolver: yupResolver(registerSchema),
  });

  const handleCommonRegulations = (id: number) => {
    setAcceptedCommonRegulations((prevState) => {
      return prevState.includes(id)
        ? prevState.filter((el) => el !== id)
        : [...prevState, id];
    });
  };

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    const { first_name, last_name, ...rest } = data;
    const payload = {
      ...rest,
      profile_attributes: { first_name, last_name },
    };
    payload.regulation_ids = [
      ...data.regulation_ids,
      ...acceptedCommonRegulations,
    ].filter((v, i, a) => a.indexOf(v) === i);

    try {
      const response = await registerRec(payload);
      if (!response) return;
      localStorage.setItem("currentUser", JSON.stringify(response.data));
      navigate("/app/tasks");
    } catch (err: unknown) {
      // @ts-ignore
      if (err && err.message) toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Wrapper
      justifyContent="center"
      flexWrap="wrap"
      flexDirection="column"
      height="100%"
      gap="30px"
    >
      <H3 variant="h3" textAlign="center">
        Rejestracja
      </H3>
      <Form onSubmit={onSubmit}>
        <Flex flexDirection="column">
          <TextField
            label="email"
            {...register("email")}
            error={t(errors.email?.message || "")}
          />
          <TextField
            type="password"
            label={t("password")}
            {...register("password")}
            error={t(errors.password?.message || "")}
          />
          <TextField
            type="password"
            label={t("password_confirmation")}
            {...register("password_confirmation")}
            error={t(errors.password_confirmation?.message || "")}
          />
          <Flex gap="5px">
            <TextField
              label={t("first_name")}
              {...register("first_name")}
              error={t(errors.first_name?.message || "")}
            />
            <TextField
              label={t("last_name")}
              {...register("last_name")}
              error={t(errors.last_name?.message || "")}
            />
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} width="100%" gap="10px">
          {regulations?.map((regulation, index) =>
            regulation.required ? (
              <Controller
                key={regulation.id}
                control={control}
                name={`regulation_ids.${index}`}
                render={({ field: { onChange, value } }) => (
                  <Box
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <Checkbox
                      onChange={(val) => {
                        onChange(val.target.checked ? regulation.id : false);
                      }}
                      checked={!!value}
                    />
                    <Link
                      to={regulation.link && `/info${regulation.link}`}
                      target={regulation.link && "_blank"}
                    >
                      {regulation.name}
                    </Link>
                  </Box>
                )}
              />
            ) : (
              <Box
                key={regulation.id}
                display="flex"
                justifyContent="start"
                alignItems="center"
              >
                <Checkbox
                  onChange={() => handleCommonRegulations(regulation.id)}
                  checked={
                    !!acceptedCommonRegulations.find(
                      (el) => el === regulation.id,
                    )
                  }
                />
                <Link
                  to={regulation.link && `/info${regulation.link}`}
                  target={regulation.link && "_blank"}
                >
                  {regulation.name}
                </Link>
              </Box>
            ),
          )}
        </Flex>

        <Box>
          {isLoading ? (
            <Spinner />
          ) : (
            <Button
              fullWidth
              type="submit"
              disabled={!formState.isValid}
              label={t("buttons.register")}
            />
          )}
        </Box>
      </Form>
      <Flex alignItems="center" justifyContent="center" gap="5px">
        <P variant="body2">Masz już konto?</P>
        <Link to="/login">{t("login")}</Link>
      </Flex>
    </Wrapper>
  );
};
