import React, { useEffect, useState } from "react";
import { H1, H2, P } from "components/Typography/Typography";
import { Box, Flex, Modal } from "components";
import ButtonsBar from "./components/ButtonsBar";
import StorageTile from "./components/StorageTile";
import {
  getStorages,
  createStorage,
  deleteStorage,
  updateStorage,
} from "services/storage";
import {
  Storage,
  CreateStorageRequest,
  UpdateStorageRequest,
} from "types/storage";
import { Wrapper, LabelRow } from "./StorageView.styled";
import { Button } from "components/_form";

export const StoragesView = () => {
  const [storages, setStorages] = useState<Storage[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedStorageId, setSelectedStorageId] = useState<string | null>(
    null,
  );
  const [selectedStorageItemName, setSelectedStorageItemName] = useState<
    string | null
  >(null);

  const openDeleteModal = (storageId: string, itemName: string) => {
    setSelectedStorageId(storageId);
    setSelectedStorageItemName(itemName);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setSelectedStorageId(null);
    setDeleteModalOpen(false);
  };

  const fetchStorages = async () => {
    try {
      const response = await getStorages();
      setStorages(response.data);
    } catch (error) {
      console.error("Nie udało się pobrać elementów magazynu", error);
    }
  };

  const handleAddStorage = async (storageData: CreateStorageRequest['storage']) => {
    try {
      const payload: CreateStorageRequest = {
        storage: {
          item: storageData.item,
          count: +storageData.count,
          min_count: +storageData.min_count,
        }
      }
      await createStorage(payload);
      await fetchStorages();
    } catch (error) {
      console.error("Nie udało się utworzyć elementu magazynu", error);
    }
  };

  const handleUpdateStorage = async (
    id: string,
    updatedStorage: UpdateStorageRequest,
  ) => {
    try {
      await updateStorage(updatedStorage, id.toString());
      await fetchStorages();
    } catch (error) {
      console.error(
        "Nie udało się zaktualizować elementu magazynu:",
        id,
        error,
      );
    }
  };

  const handleDeleteClick = async (storageId: string) => {
    try {
      await deleteStorage(storageId);
      await fetchStorages();
    } catch (error) {
      console.error(
        "Nie udało się usunąć elementu magazynu:",
        storageId,
        error,
      );
    }
  };

  const handleDeleteConfirm = async () => {
    if (selectedStorageId) {
      await handleDeleteClick(selectedStorageId);
    }
    closeDeleteModal();
  };

  useEffect(() => {
    fetchStorages();
  }, []);

  return (
    <Wrapper flexDirection="column">
      <H1 variant="h1" marginBottom="1rem">
        Zarządzanie magazynami
      </H1>
      <ButtonsBar onAddStorage={handleAddStorage} />
      <Flex flexDirection="column">
        <LabelRow>
          <span>Produkt:</span>
          <span>Ilość:</span>
          <span>Min. ilość:</span>
        </LabelRow>
        {storages.map((storage) => (
          <StorageTile
            key={storage.id}
            storage={storage}
            onUpdate={handleUpdateStorage}
            onDelete={(id) => openDeleteModal(id, storage.item)}
          />
        ))}
      </Flex>
      <Modal isOpen={deleteModalOpen} onCancelClick={closeDeleteModal}>
        <H2 variant="h2" mb={3}>
          Potwierdź usunięcie
        </H2>
        <P variant="body" mb={3}>
          Czy na pewno chcesz usunąć element magazynu {selectedStorageItemName}?
        </P>
        <Flex>
          <Button label="Potwierdź" onClick={handleDeleteConfirm} />
          <Box mr={2} />
          <Button
            label="Anuluj"
            variant="secondary"
            onClick={closeDeleteModal}
          />
        </Flex>
      </Modal>
    </Wrapper>
  );
};

export default StoragesView;
