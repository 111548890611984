import React from "react";
import styled from "styled-components";
import { Flex, Link } from "components";
import { device } from "styles/theme";
import MobileAppIcon from "components/icons/MobileAppIcon";
import { Button } from "components/_form";
import EULogos from "assets/images/eu-logos.png";
import { useTranslation } from "react-i18next";

const Wrapper = styled(Flex)`
  flex-direction: row;
  max-width: 1280px;
  width: 100%;
  @media ${device.tablet} {
    flex-direction: column;
    > :nth-child(1) {
      order: 2;
    }
    > :nth-child(2) {
      order: 3;
    }
    > :nth-child(3) {
      order: 1;
    }
  }

  justify-content: space-between;
  align-items: center;
  gap: 30px;
`;

const Image = styled.img`
  max-width: 320px;
  width: 100%;
`;

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Flex gap="20px">
        <Link to="https://apartamentakcent.pl/offers" target="_blank">
          Ogłoszenia
        </Link>
        <Link to="https://apartamentakcent.pl/txt/200/Dotacja" target="_blank">
          Dotacja
        </Link>
        <Link to="https://apartamentakcent.pl/contact" target="_blank">
          Kontakt
        </Link>
      </Flex>
      <Image src={EULogos} alt="EU logos" />
      <a href="/app-release.apk" download>
        <Button
          bordered
          label={t("buttons.downloadApp")}
          icon={<MobileAppIcon />}
        />
      </a>
    </Wrapper>
  );
};
