import styled from "styled-components";
import { Flex } from "components";

export const GuestWrapper = styled.main`
  ${({ theme }) => `
    background-color: ${theme.palette.background};
  `}
  display: grid;
  min-height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 10fr max-content;
  grid-template-areas:
    "navbar"
    "content"
    "footer";
`;

export const AppWrapper = styled.main`
  ${({ theme }) => `
    background-color: ${theme.palette.background};
  `}
  display: grid;
  min-height: 100vh;

  grid-template-columns: 1fr;
  grid-template-rows: max-content 10fr;
  grid-template-areas:
    "navbar"
    "content";
`;

export const NavbartContent = styled(Flex)`
  grid-area: navbar;
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
  `};
  justify-content: center;
  width: 100%;
  padding: 15px 20px;
`;

export const FooterContent = styled(Flex)`
  grid-area: footer;
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
  `};
  justify-content: center;
  width: 100%;
  padding: 15px 20px;
`;

export const ViewContent = styled(Flex)`
  grid-area: content;
  justify-content: center;
  width: 100%;
  padding: 20px;
`;

export const GridSidebar = styled.div`
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 30px;
  max-width: 1280px;
  width: 100%;
`;

export const AuthFlex = styled(Flex)`
  max-width: 1280px;
  width: 100%;
`;
