import { LOCATIONS } from "constants/paths";
import qs from "qs";
import { PaginationFilter } from "types/common";
import { ILocationResponse } from "types/location";
import { http } from "utilities/http";

export const getLocations = async (filters: PaginationFilter) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return await http<ILocationResponse>(`${LOCATIONS}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
