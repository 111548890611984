import { format } from "date-fns";

export function dynamicDateFormat(startDate: Date, endDate: Date) {
  const startDay = format(startDate, "dd");
  const startMonth = format(startDate, "MM");
  const startYear = format(startDate, "yyyy");
  const endDay = format(endDate, "dd");
  const endMonth = format(endDate, "MM");
  const endYear = format(endDate, "yyyy");

  if (startYear === endYear) {
    if (startMonth === endMonth) {
      return `${startDay}-${endDay}.${endMonth}.${endYear}`;
    } else {
      return `${startDay}.${startMonth}-${endDay}.${endMonth}.${endYear}`;
    }
  } else {
    return `${startDay}.${startMonth}.${startYear}-${endDay}.${endMonth}.${endYear}`;
  }
}

export const formatDate = (d: Date, char?: string) => d ? `${(d.getDate()) <= 9 ? "0" + (d.getDate()) : (d.getDate())}${char || '-'}${(d.getMonth() + 1) <= 9 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)}${char || '-'}${d.getFullYear()}` : '';
export const formatTime = (d: Date, char?: string) => d ? `${(d.getHours()) <= 9 ? "0" + (d.getHours()) : (d.getHours())}${char || '-'}${(d.getMinutes()) <= 9 ? "0" + (d.getMinutes()) : (d.getMinutes())}` : '';

export const getDateWithHours = (d: Date, char?: string, char2?:string) => formatDate(d, char) + ' ' + formatTime(d, char2)
