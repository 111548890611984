import { http } from "utilities/http";
import {
  TaskResponse,
  Task,
  CreateTaskRequest,
  UpdateTaskRequest,
} from "types/task";
import { TASKS, TASK } from "constants/paths";
import qs from "qs";
import { PaginationFilter } from "types/common";
import { serialize } from "object-to-formdata";

export interface GetTasksFilter extends PaginationFilter {
  date_from?: string | Date;
  date_to?: string | Date;
  location_id?: string;
}

export const getTasks = async (filters: GetTasksFilter) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return await http<TaskResponse>(`${TASKS}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getTask = async (id: string) => {
  return await http<Task>(TASK(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const createTask = (task: CreateTaskRequest) =>
  http<Task>(TASKS, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(task),
  });

export const updateTask = (task: UpdateTaskRequest, id: string) =>
  http<Task>(TASK(id), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize(task),
  });

export const deleteTask = (id: string) =>
  http<Task>(TASK(id), {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
