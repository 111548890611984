import React, { useState } from "react";
import styled from "styled-components";
import { P } from "components/Typography/Typography";
import { Box, Flex } from "components";
import { Storage, UpdateStorageRequest } from "types/storage";
import { ReactComponent as EditIcon } from "assets/icons/ico-edit.svg";
import { Button, TextField } from "components/_form";
import { Grid } from "../StorageView.styled";
import { ReactComponent as DeleteIcon } from "assets/icons/bx-x.svg";

const Tile = styled(Grid)`
  margin-bottom: 16px;
  padding: 24px 32px;
  min-height: 100px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

const Field = styled.div`
  display: flex;
  border-right: 1.5px solid ${({ theme }) => theme.palette.neutral.anotherGrey};
  min-height: 52px;
  align-items: center;
  margin-right: 24px;
  padding-right: 24px;
  &:nth-last-child(2) {
    border-right: none;
  }
`;

interface StorageTileProps {
  storage: Storage;
  onUpdate: (id: string, updatedStorage: UpdateStorageRequest) => void;
  onDelete: (id: string) => void;
}

const StorageTile: React.FC<StorageTileProps> = ({
  storage,
  onUpdate,
  onDelete,
}) => {
  const { id, item, count, min_count } = storage;
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedStorage, setEditedStorage] = useState(storage);

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const handleDeleteClick = () => {
    onDelete(id.toString());
  };

  const handleUpdate = () => {
    const { id, item, count, min_count } = editedStorage;
    onUpdate(id.toString(), { storage: { item, count, min_count } });
    setIsEditMode(false);
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setEditedStorage(storage);
  };

  return (
    <Tile>
      <Field>
        {isEditMode ? (
          <TextField
            hideErrorBox
            transparent
            type="text"
            value={editedStorage.item}
            onChange={(e) =>
              setEditedStorage({ ...editedStorage, item: e.target.value })
            }
          />
        ) : (
          <P variant="body">{item}</P>
        )}
      </Field>
      <Field>
        {isEditMode ? (
          <TextField
            hideErrorBox
            transparent
            type="number"
            value={editedStorage.count}
            onChange={(e) =>
              setEditedStorage({
                ...editedStorage,
                count: parseInt(e.target.value),
              })
            }
          />
        ) : (
          <P variant="body">{count}</P>
        )}
      </Field>
      <Field>
        {isEditMode ? (
          <TextField
            hideErrorBox
            transparent
            type="number"
            value={editedStorage.min_count}
            onChange={(e) =>
              setEditedStorage({
                ...editedStorage,
                min_count: parseInt(e.target.value),
              })
            }
          />
        ) : (
          <P variant="body">{min_count}</P>
        )}
      </Field>
      <Flex alignItems="center" justifyContent="end">
        {!isEditMode && (
          <DeleteIcon
            style={{
              cursor: "pointer",
              marginLeft: "auto",
              width: "20px",
              height: "20px",
              marginRight: "16px",
            }}
            onClick={handleDeleteClick}
          />
        )}
        {isEditMode ? (
          <>
            <Button label="Cofnij" variant="secondary" onClick={handleCancel} />
            <Box mr={2} />
            <Button label="Zapisz" onClick={handleUpdate} />
          </>
        ) : (
          <EditIcon
            style={{
              cursor: "pointer",
              width: "20px",
              height: "20px",
            }}
            onClick={handleEditClick}
          />
        )}
      </Flex>
    </Tile>
  );
};

export default StorageTile;
