import React from "react";
import styled from "styled-components";
import {
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  TablePropGetter,
  TableProps,
} from "react-table";
import { format } from "date-fns";

export interface ITable<T extends Object> {
  getTableProps: (propGetter?: TablePropGetter<T>) => TableProps;
  headerGroups: Array<HeaderGroup<T>>;
  rows: Array<Row<T>>;
  prepareRow: (row: Row<T>) => void;
  getTableBodyProps: (propGetter?: TableBodyPropGetter<T>) => TableBodyProps;
}

const StyledTable = styled.table`
  border-collapse: collapse;
  font-size: 14px;
`;

const StyledHeader = styled.thead`
  th {
    border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
    color: ${({ theme }) => theme.palette.neutral.lightGrey};
    font-weight: normal;
    padding: 10px;
    text-align: left;
    font-size: 14px;
  }
`;
const StyledBody = styled.tbody`
  tr {
  }
  td {
    padding: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  }
`;

const ExpandedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.background};
  > div {
    padding: 10px;

    p:first-child {
      margin-bottom: 10px;
      font-size: 12px;
      font-weight: 600;
    }
  }
`;

// @ts-ignore
export const Table: React.FC<ITable<T>> = ({
  getTableProps,
  headerGroups,
  rows,
  prepareRow,
  getTableBodyProps,
}) => {
  return (
    <StyledTable {...getTableProps()}>
      <StyledHeader>
        {headerGroups.map((headerGroup) => {
          return (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                // @ts-ignore
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                >
                  {column.render("Header")}
                  {/*// @ts-ignore*/}
                  {column.isSorted
                    ? // @ts-ignore
                      column.isSortedDesc
                      ? " ⬇️"
                      : " ⬆️"
                    : ""}
                </th>
              ))}
            </tr>
          );
        })}
      </StyledHeader>
      <StyledBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          // @ts-ignore
          if (row.depth > 0) {
            return (
              <tr key={row.id}>
                <td colSpan={7} style={{ textAlign: "center", padding: "0px" }}>
                  <ExpandedWrapper>
                    <div>
                      <p>ID</p>
                      <p>{row.original.id}</p>
                    </div>
                    <div>
                      <p>Data od</p>
                      <p>
                        {row.original.date_from &&
                          format(
                            new Date(row.original.date_from),
                            "dd.MM.yyyy', 'HH:mm",
                          )}
                      </p>
                    </div>
                    <div>
                      <p>Data do</p>
                      <p>
                        {row.original.date_to &&
                          format(
                            new Date(row.original.date_to),
                            "dd.MM.yyyy', 'HH:mm",
                          )}
                      </p>
                    </div>
                    <div>
                      <p>Notatki</p>
                      <p>{row.original.note}</p>
                    </div>
                    <div>
                      <p>Iłość osób</p>
                      <p>{row.original.number_of_people}</p>
                    </div>
                  </ExpandedWrapper>
                </td>
              </tr>
            );
          }
          return (
            // @ts-ignore
            <tr
              style={{ borderBottom: row.isExpanded && "unset" }}
              {...row.getRowProps()}
              key={row.id}
            >
              {row.cells.map((cell, index) => {
                return (
                  <td {...cell.getCellProps()} key={cell.row.id + "_" + index}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </StyledBody>
    </StyledTable>
  );
};
