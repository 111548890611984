import React, { useEffect, useState } from "react";
import { Flex, Modal } from "components";
import { H3, P } from "components/Typography/Typography";
import { Apartment, Reservation } from "types/apartment";
import {
  generateApartmentCode,
  getReservations,
  sendApartmentCode,
} from "services/apartments";
import { format } from "date-fns";
import { Button } from "components/_form";
import { dynamicDateFormat, getDateWithHours } from "utilities/dates";
import {
  Comments,
  SeparatorStyled,
} from "views/TasksView/components/TaskDetailsModal";
import { Grid } from "../ReservationsView.styled";
import { toast } from "react-toastify";

interface ReservationsDetailsModalProps {
  isOpen: boolean;
  apartment: Apartment;
  onCancelClick: () => void;
  refetchData: () => any;
}

const ReservationsDetailsModal: React.FC<ReservationsDetailsModalProps> = ({
  isOpen,
  apartment,
  onCancelClick,
  refetchData,
}) => {
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [showAllReservations, setShowAllReservations] =
    useState<boolean>(false);
  const apartmentId = apartment.id;

  const fetchReservations = async (apartmentId: number) => {
    try {
      const response = await getReservations({}, apartmentId.toString());
      setReservations(response.data);
    } catch (error) {
      console.error("Failed to fetch reservations", error);
    }
  };

  const generateCode = async () => {
    if (!apartment?.lock?.id) return;
    const futureReservation = reservations?.[1];
    try {
      await generateApartmentCode(apartment.lock.id, {
        valid_from: getDateWithHours(
          new Date(`${futureReservation?.date_from}`),
          ".",
          ":",
        ),
        valid_to: getDateWithHours(
          new Date(`${futureReservation?.date_to}`),
          ".",
          ":",
        ),
        // "valid_to": "24.07.2023 12:00"
      });
      toast.success("Kod został wygenerowany");
      onCancelClick();
      refetchData();
    } catch (error) {
      toast.error("Wystąpił błąd");
      console.error("Wystąpił błąd", error);
    }
  };

  const sendCode = async () => {
    if (!apartment?.lock?.id) return;
    try {
      await sendApartmentCode(apartment.lock.id, { user_id: 1 });
      toast.success("Kod został wysłąny");
      onCancelClick();
      refetchData();
    } catch (error) {
      toast.error("Wystąpił błąd");
      console.error("Wystąpił błąd", error);
    }
  };

  const handleClose = () => {
    setShowAllReservations(false);
    onCancelClick();
  };

  useEffect(() => {
    if (apartmentId) fetchReservations(apartmentId);
  }, [apartmentId]);

  const renderReservations = () => {
    const now = new Date();
    const sortedReservations = reservations.sort(
      (a, b) => new Date(b.date_to).getTime() - new Date(a.date_to).getTime(),
    );
    const lastReservation = sortedReservations.find(
      (reservation) => new Date(reservation.date_to) < now,
    );
    const futureReservation = sortedReservations.find(
      (reservation) => new Date(reservation.date_from) > now,
    );

    const filteredReservations = showAllReservations
      ? reservations
      : [lastReservation, futureReservation].filter((res) => res !== undefined);

    return (
      <>
        {!showAllReservations && (
          <Flex alignItems="center" pb="10px">
            <P variant="body2" fontWeight={600} color="dark">
              Pokazuje tylko najbliższe rezerwacje (ostatnia i przyszła)
            </P>
          </Flex>
        )}
        {!!filteredReservations.length ? (
          filteredReservations.map((reservation, index) => {
            if (!reservation) return null;
            return (
              <React.Fragment key={index}>
                <Grid cols={2} style={{ gap: "10px" }}>
                  <P variant="body2" fontWeight={600}>
                    Rezerwacja {reservation.id}:
                  </P>
                  <P variant="body2">
                    {dynamicDateFormat(
                      new Date(reservation.date_from),
                      new Date(reservation.date_to),
                    )}
                  </P>
                  <P variant="body2" fontWeight={600}>
                    Przyjazd:
                  </P>
                  <P variant="body2">
                    {format(
                      new Date(reservation.date_from),
                      "dd.MM.yyyy', 'HH:mm",
                    )}
                  </P>
                  <P variant="body2" fontWeight={600}>
                    Wyjazd:
                  </P>
                  <P variant="body2">
                    {format(
                      new Date(reservation.date_to),
                      "dd.MM.yyyy', 'HH:mm",
                    )}
                  </P>

                  <P variant="body2" fontWeight={600}>
                    Liczba osób:
                  </P>
                  <P variant="body2">{reservation.number_of_people}</P>

                  <P variant="body2" fontWeight={600}>
                    Uwagi klienta:
                  </P>
                </Grid>
                <Comments>
                  {" "}
                  {reservation.note ? reservation.note : "Brak"}
                </Comments>
                {!(index === filteredReservations.length - 1) && (
                  <SeparatorStyled />
                )}
              </React.Fragment>
            );
          })
        ) : (
          <P variant="body2">Brak rezerwacji</P>
        )}
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} onCancelClick={handleClose} disableBackdropClick>
      <Flex flexDirection="column">
        <Flex flexDirection="column" gap="5px">
          <H3 variant="h3">{apartment.location?.name}</H3>
          <P variant="body2" color="grey">
            {apartment.name}
          </P>
        </Flex>
        <SeparatorStyled />
        {renderReservations()}
        <SeparatorStyled />
        <Flex justifyContent="space-between" alignItems="center">
          <Button
            variant="secondary"
            bordered
            label="Zamknij"
            onClick={handleClose}
          />
          <Button
            variant="primary"
            label="Wygeneruj kod"
            onClick={generateCode}
          />
          <Button variant="primary" label="Wyślij kod" onClick={sendCode} />
          <Button
            variant="primary"
            label={showAllReservations ? "Pokaż najbliższe" : "Pokaż wszystkie"}
            onClick={() => setShowAllReservations(!showAllReservations)}
          />
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ReservationsDetailsModal;
