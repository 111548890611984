import { Box, Flex } from "components";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getApartments } from "services/apartments";
import { Apartment } from "types/apartment";
import { Task } from "types/task";
import { CustomDatePicker } from "../components/CustomDatePicker";
import { FieldLabel as Label, Select } from "components/_form";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { GetTasksFilter } from "services/tasks";
import { pl } from "date-fns/locale";
import { H4, P } from "components/Typography/Typography";
import styled from "styled-components";
import { IOption } from "components/_form/Select/Select";
import { getLocations } from "services/locations";
import { rgba } from "utilities/rgba";
import { Status } from "components/Legend/Legend";
import { getUsers } from "services/users";

interface MonthlyTasksProps {
  tasks: Task[];
  filterOptions: GetTasksFilter;
  setFilterOptions: Dispatch<SetStateAction<GetTasksFilter>>;
}

interface IParsedUser {
  id: number;
  initials: string;
}

const Th = styled.th`
  background-color: ${({ theme }) => theme.palette.background};
  border: 1px solid ${({ theme }) => theme.palette.neutral.grey};
  border-collapse: collapse;
  font-weight: normal;
  min-width: 50px;
  width: 50px;
`;

const FirstTh = styled(Th)`
  border-left: none;
  background-color: #fff;
  max-width: 300px;
  position: sticky;
  left: 0;
`;

const Td = styled.td`
  border: 1px solid ${({ theme }) => theme.palette.neutral.grey};
  border-collapse: collapse;
  min-width: 50px;
  width: 50px;
  text-align: center;
  position: relative;
`;

const FirstTd = styled(Td)`
  width: 300px;
  min-width: 300px;
  position: sticky;
  left: 0;
  border-left: none;
  z-index: 2;
`;

const Row = styled.tr`
  font-size: 14px;
  height: 50px;
  background-color: white;
`;

const TableWrapper = styled.div`
  display: block;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 25px;
`;

const Table = styled.table`
  border-collapse: separate;
  width: 100%;
`;

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding-inline: 40px;
  justify-items: stretch;
  align-items: baseline;
`;
const StatusBox = styled(Box)`
  position: absolute;
  top: 1px;
  right: 1px;
`;

const MonthlyTasksView = ({
  tasks,
  setFilterOptions,
  filterOptions,
}: MonthlyTasksProps) => {
  const [apartments, setApartments] = useState<Apartment[]>([]);
  const [preparedApartments, setPreparedApartments] = useState<Apartment[]>([]);
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));
  const [options, setOptions] = useState<IOption[]>([]);
  const [users, setUsers] = useState<IParsedUser[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string>("all");

  const fetchUser = async () => {
    try {
      const response = await getUsers();
      var fetchedUser = new Array();
      if (response.data.length) {
        response.data.map((user) => {
          fetchedUser.push({
            id: user.id,
            initials:
              user.profile.first_name.charAt(0) +
              user.profile.last_name.charAt(0),
          });
        });
      }
      return setUsers(fetchedUser);
    } catch (error) {
      console.error("Nie udało się pobrać użytkowników", error);
    }
  };

  const fetchOptions = async () => {
    try {
      const response = await getLocations({});
      const options: IOption[] = response.data.map((e) => {
        return { label: e.name, value: String(e.id) };
      });
      setOptions(options);
    } catch (error) {
      console.error("Nie udało się pobrać lokalizacji", error);
    }
  };

  const fetchApartments = async () => {
    try {
      const response = await getApartments({});
      setApartments(response.data);
    } catch (error) {
      console.error("Nie udało się pobrać obiektów", error);
    }
  };

  useEffect(() => {
    fetchApartments();
    fetchOptions();
    fetchUser();
  }, []);

  const getDaysArray = (start: Date, end: Date) => {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  useEffect(() => {
    setFilterOptions({ date_from: startDate, date_to: endDate });
  }, [endDate, startDate]);

  const prepareApartments = () => {
    const filteredApartments: Apartment[] = apartments
      .filter((apartment) => {
        if (selectedLocation === "all") return apartment;
        else return String(apartment.location?.id ?? "") === selectedLocation;
      })
      .sort((a, b) =>
        (a.location?.name ?? "").localeCompare(b.location?.name ?? ""),
      );
    return setPreparedApartments(filteredApartments);
  };

  useEffect(() => {
    prepareApartments();
  }, [selectedLocation, apartments]);

  const getFilteredTasks = (
    date: Date,
    apartmentId?: number,
    userId?: number,
  ) => {
    const tasksForDay = tasks.filter(
      (task) =>
        format(new Date(task.planned_date_from), "yyyy-MM-dd") ===
        format(date, "yyyy-MM-dd"),
    );

    let filteredTasks: Task[] = tasksForDay;

    if (selectedLocation !== "all") {
      filteredTasks = tasksForDay.filter(
        (task) => task.apartment.location?.id === Number(selectedLocation),
      );
    }

    if (apartmentId) {
      filteredTasks = tasksForDay.filter(
        (task) => task.apartment.id === apartmentId,
      );
    }

    if (userId) {
      filteredTasks = tasksForDay.filter((task) => task.user.id === userId);
    }
    return filteredTasks;
  };

  const getUserInitials = (tasks: Task[]) => {
    let initialsArray: string[] = new Array();
    tasks.map((task) => {
      initialsArray.push(
        users.find((user) => user.id === task.user.id)?.initials ?? "",
      );
    });

    return initialsArray
      .filter((item, index) => initialsArray.indexOf(item) === index)
      .join(", ");
  };

  const getTdValue = (date: Date, id: number) => {
    const tasks = getFilteredTasks(date, id);

    return tasks.length ? (
      <>
        {tasks.length}
        <StatusBox>
          <Status value={tasks[tasks.length - 1].status} small />
        </StatusBox>
      </>
    ) : (
      <></>
    );
  };

  return (
    <Flex flexDirection="column" gap="20px" width="100%">
      <Grid>
        <Flex flexDirection="column" width="100%">
          <Label>Data od:</Label>
          <CustomDatePicker
            value={startDate}
            onChange={(date: Date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />
        </Flex>

        <Flex flexDirection="column">
          <Label>Data do:</Label>
          <CustomDatePicker
            value={endDate}
            onChange={(date: Date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
        </Flex>
        <Select
          options={options}
          label="Obiekt:"
          isClearable
          isMulti={false}
          onChange={(e: IOption) => {
            if (e === null) setSelectedLocation("all");
            else if (e.value) setSelectedLocation(e.value);
          }}
          hideError
        />
      </Grid>
      <TableWrapper>
        <Table cellSpacing={0}>
          <tbody>
            <Row>
              <FirstTh />
              {getDaysArray(startDate, endDate).map((date) => (
                <Th key={String("columnn-" + date)} align="center">
                  <Flex flexDirection="column">
                    <H4 variant="h4" color="grey" fontWeight={400}>
                      {format(date, "EEE", { locale: pl })}
                    </H4>
                    <P variant="body2" fontWeight={400}>
                      {format(date, "dd", { locale: pl })}
                    </P>
                  </Flex>
                </Th>
              ))}
            </Row>
            <Row>
              <FirstTd style={{ backgroundColor: "white" }}>
                <H4 variant="h4">Pracownicy</H4>
              </FirstTd>
              {getDaysArray(startDate, endDate).map((date) => (
                <Td key={String("columnn-" + date)} align="center">
                  {getUserInitials(getFilteredTasks(date))}
                </Td>
              ))}
            </Row>
            <Row>
              <FirstTd style={{ backgroundColor: "white" }}>
                <H4 variant="h4">Suma zadań</H4>
              </FirstTd>
              {getDaysArray(startDate, endDate).map((date) => (
                <Td key={String("columnn-" + date)} align="center">
                  {getFilteredTasks(date).length}
                </Td>
              ))}
            </Row>
            {preparedApartments.map(({ location, id, short_name }, key) => (
              <Row key={key}>
                <FirstTd
                  style={{
                    backgroundColor:
                      location && location.color ? location.color : "white",
                  }}
                >
                  {short_name}
                </FirstTd>
                {getDaysArray(startDate, endDate).map((date) => {
                  return (
                    <Td
                      key={String("row-" + date)}
                      align="center"
                      style={{
                        backgroundColor:
                          location && location.color
                            ? rgba(location.color, 0.7)
                            : "white",
                      }}
                    >
                      {getTdValue(date, id)}
                    </Td>
                  );
                })}
              </Row>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </Flex>
  );
};

export default MonthlyTasksView;
