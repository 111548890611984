import { FC } from "react";
import { Flex } from "components";

export const ExampleView: FC = () => {
  return (
    <Flex justifyContent="space-between" flexWrap="wrap">
      Hello
    </Flex>
  );
};
