const common = {
  "privacyPolicy": "privacy policy",
  "regulamin": "regulamin",
  "first_name": "first name",
  "last_name": "last name",
}

const auth = {
  "login": "login",
  "register": "register",
  "password": "password",
  "forgotPass": "Forgot password?",
  "accountless": "Don\'t have account?",
  "emailRequired": "Email is required",
  "badEmailFormat": "Invalid email format",
  "passwordRequired": "Password is required",
  "passMinVal": "The password should contain at least 6 characters",
  "passMaxVal": "The password can be up to 18 characters long",
  "passDiffErr": "The passwords do not match",
  "acceptRegulamin": "Accept the terms and conditions",
  "password_confirmation": "password confirmation",
  "send_email": "Send e-mail",
}

export default {
  ...common,
  ...auth
}
