import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "components/_form/Button/Button";
import { Flex } from "components";
import { FieldLabel as Label } from "components/_form/FieldLabel";
import { Select } from "components/_form";
import { ReactComponent as Sort } from "assets/icons/ico-sort.svg";
import { IOption } from "components/_form/Select/Select";
import { getLocations } from "services/locations";
import { GetTasksFilter } from "services/tasks";
import { CustomDatePicker } from "./CustomDatePicker";

const ButtonsBarWrapper = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  width: 100%;
  align-items: end;
`;

interface ButtonsBarProps {
  filter: GetTasksFilter;
  setFilter: React.Dispatch<React.SetStateAction<GetTasksFilter>>;
}

const ButtonsBar: React.FC<ButtonsBarProps> = ({ filter, setFilter }) => {
  const [options, setOptions] = useState<IOption[]>([]);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    new Date(filter.date_from ?? ""),
    new Date(filter.date_to ?? ""),
  ]);

  const fetchOptions = async () => {
    try {
      const response = await getLocations({});
      const options: IOption[] = response.data.map((e) => {
        return { label: e.name, value: String(e.id) };
      });
      setOptions(options);
    } catch (error) {
      console.error("Nie udało się pobrać obiektów", error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    const [start, end] = dateRange;
    setFilter((prev) => {
      return {
        ...prev,
        date_from: start ?? "",
        date_to: end ?? "",
      };
    });
  }, [dateRange]);

  return (
    <>
      <ButtonsBarWrapper>
        <Flex flexDirection="column" width="100%">
          <Label>Data:</Label>
          <CustomDatePicker
            rangePicker
            value={dateRange[0] ?? new Date()}
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            onChange={(update) => {
              setDateRange(update);
            }}
          />
        </Flex>
        <Select
          options={options}
          label="Obiekt:"
          isClearable
          isMulti={false}
          hideError
          onChange={(e: IOption) => {
            setFilter((prev) => {
              return {
                ...prev,
                location_id: e ? e.value : "",
              };
            });
          }}
        />
        <Flex />

        <Button label="Od najnowszych" variant="secondary" bordered fullWidth>
          <Sort />
        </Button>
      </ButtonsBarWrapper>
    </>
  );
};

export default ButtonsBar;
