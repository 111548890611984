import { ReactComponent as UserIcon } from "assets/icons/ico-avatar.svg";
import React from "react";
import styled from "styled-components";

export interface AvatarI {
  size?: "small" | "medium" | "large";
  url?: string;
  border?: boolean;
}

interface RootI {
  size: AvatarI["size"];
  border: AvatarI["border"];
}

interface ImageI {
  url?: AvatarI["url"];
}

const Root = styled.div<RootI>`
  ${({ size, theme, border }) => `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: ${
      (size === "small" && `40px`) ||
      (size === "medium" && `64px`) ||
      (size === "large" && `128px`)
    };
    ${border && `border: 2px solid ${theme.palette.primary.mainBlue}`};
    aspect-ratio: 1/1;
    flex: 0 0 auto;
    border-radius: 50%;
    color: ${theme.palette.neutral.dark};
    background-color: ${theme.palette.primary.main};
    overflow: hidden;
  `}
`;

const Image = styled.div<ImageI>`
  ${({ url }) => `
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    ${url && `background-image: url('${url}');`}
    background-position: center;
    background-size: cover;
  `}
`;

export const Avatar: React.FC<AvatarI> = ({ size = "small", url, border }) => (
  <Root size={size} border={border}>
    <UserIcon />
    <Image url={url} />
  </Root>
);
