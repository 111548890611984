import React, { useState } from "react";
import { Flex, Modal } from "components";
import { Box } from "components/Box";
import { H3, P } from "components/Typography/Typography";
import { Task } from "types/task";
import styled from "styled-components";
import { format, formatDuration } from "date-fns";
import { Button } from "components/_form";
import { Status } from "components/Legend/Legend";
import Images from "./Images";
import DeleteIcon from "components/icons/DeleteIcon";
import EditIcon from "components/icons/EditIcon";
import CalendarIcon from "components/icons/CalendarIcon";
import LastAndComingReservations from "./LastAndComingReservations";
import CreateEditTaskModal from "./CreateEditTaskModal";

interface TaskDetailsModalProps {
  isOpen: boolean;
  task?: Task;
  onCancelClick: () => void;
  onDeleteClick: (taskId: string) => void;
  onUpdateClick: (task: any, taskId: string) => void;
}

export const SeparatorStyled = styled.div`
  width: 100%;
  height: 1px;
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.anotherGrey};
  `};
  margin: 15px 0;
`;

export const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  align-items: center;
`;

export const Comments = styled(Box)`
  margin-top: 15px;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.palette.neutral.light};
`;

const TaskDetailsModal: React.FC<TaskDetailsModalProps> = ({
  isOpen,
  task,
  onCancelClick,
  onDeleteClick,
  onUpdateClick,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  const handleEditClick = () => {
    setShowEditForm(true);
  };

  if (!task) return <></>;

  const {
    apartment,
    planned_date_from,
    planned_date_to,
    status,
    note,
    duration: seconds,
    photos_urls,
  } = task;

  const duration = {
    hours: Math.floor(seconds / 3600),
    minutes: Math.floor((seconds % 3600) / 60),
  };
  const formattedDuration = formatDuration(duration, {
    format: ["godz", "min"],
  });

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick} disableBackdropClick>
      {showEditForm ? (
        <CreateEditTaskModal
          onUpdate={onUpdateClick}
          task={task}
          onCancelClick={() => setShowEditForm(false)}
          isOpen={showEditForm}
          editMode
        />
      ) : (
        <Flex flexDirection="column" width="100%">
          <Flex gap="10px">
            <Box flexShrink={0}>
              <Status value={status} />
            </Box>
            <Flex flexDirection="column" gap="5px">
              <H3 variant="h3">{apartment.location?.name}</H3>
              <P variant="body2" color="grey">
                {" "}
                {apartment.name}
              </P>
            </Flex>
          </Flex>
          <SeparatorStyled />
          <Grid>
            <Flex gap="5px" alignItems="center">
              <CalendarIcon />
              <P variant="body2" fontWeight={600}>
                Planowana data rozpoczęcia:
              </P>
            </Flex>

            <P variant="body2">
              {format(new Date(planned_date_from), "dd.MM.yyyy', godz.' HH:mm")}
            </P>
            <Flex gap="5px" alignItems="center">
              <CalendarIcon />
              <P variant="body2" fontWeight={600}>
                Planowana data zakończenia:
              </P>
            </Flex>
            <P variant="body2">
              {format(new Date(planned_date_to), "dd.MM.yyyy', godz.' HH:mm")}
            </P>
            <P variant="body2" fontWeight={600}>
              Status:
            </P>
            <Status value={status} />
          </Grid>
          <SeparatorStyled />
          <Grid>
            <P variant="body2" fontWeight={600}>
              Zaraportowany czas:
            </P>
            <P variant="body2">
              {formattedDuration ? formattedDuration : "00:00"}
            </P>
            <P variant="body2" fontWeight={600}>
              Zdjęcia:
            </P>
            <Images images={photos_urls ?? []} />
            <P variant="body2" fontWeight={600}>
              Notatki:
            </P>
            <P variant="body2">{note ? note : "Brak"}</P>
          </Grid>

          <LastAndComingReservations apartmentId={task.apartment.id} />
          <SeparatorStyled />
          <Flex justifyContent="space-between">
            {!showDeleteConfirmation && (
              <Button
                bordered
                variant="secondary"
                label="Zamknij"
                onClick={onCancelClick}
              />
            )}

            {showDeleteConfirmation ? (
              <Flex
                justifyContent="end"
                alignItems="center"
                gap="10px"
                width="100%"
              >
                <P variant="body2">
                  Czy jesteś pewien, że chcesz usunąć zadanie?
                </P>
                <Button
                  bordered
                  variant="secondary"
                  label="Potwierdź"
                  onClick={() => {
                    try {
                      onDeleteClick(task.id.toString());
                    } finally {
                      setShowDeleteConfirmation(false);
                    }
                  }}
                />
                <Button
                  variant="primary"
                  label="Cofnij"
                  onClick={() => setShowDeleteConfirmation(false)}
                />
              </Flex>
            ) : (
              <Flex justifyContent="end" gap="10px">
                <Button
                  variant="primary"
                  label="Edytuj"
                  onClick={() => handleEditClick()}
                  icon={<EditIcon />}
                />
                <Button
                  bordered
                  variant="secondary"
                  label="Usuń"
                  onClick={() => setShowDeleteConfirmation(true)}
                  icon={<DeleteIcon />}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      )}
    </Modal>
  );
};

export default TaskDetailsModal;
