import { Card } from "components/Card";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import React, { CSSProperties, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { rgba } from "utilities/rgba";

const modalRoot = document.getElementById("modal-root")!;

export interface IModal {
  isOpen: boolean;
  onCancelClick: () => void;
  disableBackdropClick?: boolean;
  style?: CSSProperties;
  children?: React.ReactNode;
}

const WrapperModal = styled.div`
  ${({ theme }) => `
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    background-color: ${rgba(theme.palette.neutral.dark, 0.3)};
    overflow-y: scroll;
    padding-block: 10px;
  `}
`;

const StyledCard = styled(Card)`
  padding: 24px 32px;
  width: 100%;
  max-width: 650px;
  overflow: visible;
  margin: auto 0px;
`;

export const Modal: React.FC<IModal> = ({
  isOpen,
  onCancelClick,
  style,
  children,
  disableBackdropClick,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(wrapperRef, () => {
    if (!disableBackdropClick) onCancelClick();
  });

  return ReactDOM.createPortal(
    <>
      {isOpen ? (
        <WrapperModal style={style}>
          <StyledCard ref={wrapperRef}>{children}</StyledCard>
        </WrapperModal>
      ) : null}
    </>,
    modalRoot,
  );
};
