import React, { useState } from "react";
import { Flex } from "components/Flex";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface IMenuItem {
  path: string;
  label: string;
  private: boolean;
  type?: string;
}
export interface IMenu {
  items?: Array<IMenuItem>;
  onMenuIconClick: () => void;
  view?: "horizontal" | "vertical";
}
interface IListItem {
  active?: boolean;
}

const NavigationLink = styled(NavLink)`
  text-align: center;
  ${({ theme }) => `
    display: block;
    color: ${theme.palette.text.primary};
    text-decoration: none;
    font-size: 14px;
  `};
`;
const ListItem = styled(Flex)<IListItem>`
  text-transform: capitalize;
  ${({ theme, active }) => `
  height: 34px;
  background-color: ${active && theme.palette.neutral.lightBlue};    
  font-weight: 500;
      &:hover {    
        background-color: ${theme.palette.neutral.lightBlue};        
        color: ${theme.palette.neutral.mainBlue};     
      }
  `}
`;

export const Menu = ({
  items,
  onMenuIconClick,
  view = "horizontal",
}: IMenu) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [active, setIsActive] = useState<string>(pathname || "");

  const handleOnClick = (path: string) => {
    onMenuIconClick();
    setIsActive(path);
  };

  const renderItems = () => {
    if (!items) return;
    return items.map(({ path, label }) => {
      const isItemSelected = active === path;

      return (
        <NavigationLink to={path} key={label}>
          <ListItem
            active={isItemSelected}
            px={3}
            alignItems="center"
            onClick={() => handleOnClick(path)}
          >
            {t(label)}
          </ListItem>
        </NavigationLink>
      );
    });
  };

  return (
    <Flex width="100%" flexDirection={view === "horizontal" ? "column" : "row"}>
      {renderItems()}
    </Flex>
  );
};
