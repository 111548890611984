const ArrowUp = ({ ...props }) => {
  return (
    <svg
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5_179)">
        <path
          d="M8.1001 5L4.5001 1L0.900098 5"
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_179">
          <rect
            width="6"
            height="9"
            rx="2"
            transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 9 6)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowUp;
