import React, { useEffect, useState } from "react";
import { H3 } from "components/Typography/Typography";
import { Flex, TabMenu } from "components";
import {
  getTasks,
  createTask,
  deleteTask,
  updateTask,
  GetTasksFilter,
} from "services/tasks";
import { Task, CreateTaskRequest, UpdateTaskRequest } from "types/task";
import { Wrapper } from "./TasksView.styled";
import { startOfDay, endOfDay, format } from "date-fns";
import TaskDetailsModal from "./components/TaskDetailsModal";
import { Button } from "components/_form";
import CreateEditTaskModal from "./components/CreateEditTaskModal";
import AddIcon from "components/icons/AddIcon";
import DailyTasksView from "./DailyTasksView/DailyTasksView";
import MonthlyTasksView from "./MonthlyTasksView/MonthlyTasksView";

export const TasksView = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [filterOptions, setFilterOptions] = useState<GetTasksFilter>({
    date_to: new Date(),
    date_from: new Date(),
  });
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalDetailsOpen, setIsModalDetailsOpen] = useState(false);
  const [modalTask, setModalTask] = useState<Task>();
  const [activeTab, setActiveTab] = useState<number>(1);

  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);

  const fetchTasks = async () => {
    try {
      const getTasksFilter = {
        date_from: format(startOfDay(filterOptions.date_from), "yyyy-MM-dd"),
        date_to: format(endOfDay(filterOptions.date_to), "yyyy-MM-dd"),
        ...(filterOptions.location_id && {
          location_id: filterOptions.location_id,
        }),
        ...(activeTab === 1 && { per_page: 10, page }),
      };
      const response = await getTasks(getTasksFilter);
      setTasks(response.data);
      setTotal(response.pagination?.pages ?? 0);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch tasks", error);
    }
  };

  const handleUpdateClick = async (task: UpdateTaskRequest, taskId: string) => {
    try {
      await updateTask(task, taskId);
      const fetchedTasks = await fetchTasks();
      const updatedTask = fetchedTasks?.find((t) => t.id.toString() == taskId);
      if (updatedTask) {
        setModalTask(updatedTask);
      }
    } catch (error) {
      console.error("Failed to update task:", taskId, error);
    }
  };

  const handleAddTask = async (taskData: CreateTaskRequest) => {
    try {
      await createTask(taskData);
      await fetchTasks();
    } catch (error) {
      console.error("Failed to create task", error);
    }
  };

  const handleDeleteClick = async (taskId: string) => {
    try {
      await deleteTask(taskId);
      setIsModalDetailsOpen(false);
      setModalTask(undefined);
      await fetchTasks();
    } catch (error) {
      console.error("Failed to delete task:", taskId, error);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [filterOptions, page]);

  useEffect(() => {
    if (activeTab === 1) {
      setFilterOptions((prev) => {
        return { ...prev, date_from: new Date(), date_to: new Date() };
      });
    }
  }, [activeTab]);

  return (
    <Wrapper flexDirection="column" width="100%" maxWidth="1070px">
      <Flex flexDirection="column" p="40px">
        <Flex justifyContent="space-between" alignItems="baseline">
          <H3 variant="h3" marginBottom="1rem">
            Harmonogram prac
          </H3>
          <TabMenu
            items={[
              { id: 1, label: "Zestawienie dzienne" },
              { id: 2, label: "Zestawienie miesięczne" },
            ]}
            active={activeTab}
            setActive={setActiveTab}
          />
          <Button
            variant="primary"
            label="Dodaj nowe zadanie"
            onClick={() => setIsModalAddOpen(true)}
            icon={<AddIcon />}
          />
        </Flex>
      </Flex>
      {activeTab === 1 && (
        <DailyTasksView
          setFilterOptions={setFilterOptions}
          setModalTask={setModalTask}
          setIsModalDetailsOpen={setIsModalDetailsOpen}
          onUpdateClick={handleUpdateClick}
          filterOptions={filterOptions}
          tasks={tasks}
          totalPages={total}
          page={page}
          setPage={setPage}
        />
      )}
      {activeTab === 2 && (
        <MonthlyTasksView
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          tasks={tasks}
        />
      )}

      <TaskDetailsModal
        task={modalTask}
        isOpen={isModalDetailsOpen}
        onCancelClick={() => {
          setIsModalDetailsOpen(false);
          setModalTask(undefined);
        }}
        onDeleteClick={handleDeleteClick}
        onUpdateClick={handleUpdateClick}
      />
      <CreateEditTaskModal
        isOpen={isModalAddOpen}
        onCancelClick={() => setIsModalAddOpen(false)}
        onSubmit={handleAddTask}
      />
    </Wrapper>
  );
};

export default TasksView;
