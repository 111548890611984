import { Flex } from "components/Flex";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { rgba } from "utilities/rgba";
import ArrowLeft from "components/icons/ArrowLeft";
import ArrowRight from "components/icons/ArrowRight";

interface IPagination {
  current: number;
  total: number;
  setCurrent: Dispatch<SetStateAction<number>>;
}

const Button = styled(Flex)<{ disabled: boolean }>`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.palette.neutral.lightGrey : theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.neutral.white};
  ${({ disabled }) => disabled && "cursor:auto"}
  :hover {
    background-color: ${({ theme, disabled }) =>
      !disabled && `${rgba(theme.palette.primary.main, 0.5)}`};
  }
  transition: 150ms linear;
`;

export const Pagination = ({ current, total, setCurrent }: IPagination) => {
  return (
    <Flex
      gap="10px"
      justifyContent="center"
      alignItems="center"
      style={{ fontSize: "14px" }}
    >
      <Button
        disabled={current <= 1}
        onClick={() => {
          if (current > 1) setCurrent((prev) => prev - 1);
        }}
      >
        <ArrowLeft />
      </Button>

      <span>{current}</span>
      <span>{"z"}</span>
      <span>{total}</span>
      <Button
        disabled={current === total}
        onClick={() => {
          if (current < total) setCurrent((prev) => prev + 1);
        }}
      >
        <ArrowRight />
      </Button>
    </Flex>
  );
};
