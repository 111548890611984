import { Flex } from "components";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  border-radius: 10px;
  height: 100%;
  padding: 40px;
  background-color: white;
`;

export const Grid = styled.div<{ cols?: number }>`
  display: grid;
  grid-template-columns: repeat(${({ cols }) => (cols ? cols : 4)}, 1fr);
  align-items: center;
  width: 100%;
`;

export const LabelRow = styled(Grid)`
  padding: 0 32px;
  font-weight: 400;
  grid-template-columns: repeat(4, 1fr) 20px;
  font-size: 18px;
  line-height: 24px;
  ${({ theme }) => `
    color: ${theme.palette.neutral.lightGrey};
  `};
`;
