import { FieldLabel as Label } from "components/_form/FieldLabel";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import React, { InputHTMLAttributes, useRef, useState } from "react";
import styled from "styled-components";
import { rgba } from "utilities/rgba";
import { ErrorText } from "../ErrorText";

export interface IHint {
  label: string;
  value: string;
}

export interface TextFieldType extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  width?: string;
  disabled?: InputHTMLAttributes<HTMLInputElement>["disabled"];
  error?: string;
  unit?: string;
  icon?: React.ReactNode;
  hints?: Array<IHint>;
  onHintClick?: (hint: IHint) => void;
  onIconClick?: (e: any) => void;
  transparent?: boolean;
  bgColor?: string;
  type?: string;
  hideErrorBox?: boolean;
}

interface IStyledInput {
  error?: TextFieldType["error"];
  unit?: TextFieldType["unit"];
  icon?: TextFieldType["icon"];
  bgColor?: TextFieldType["bgColor"];
  displayHints?: boolean;
  transparent?: boolean;
}

const unitWidthInPx = 44;
const iconSizeInPx = 44;
const iconPositionRight = 4;

const Root = styled.div<{ width?: string }>`
  ${({ width }) => `
      width: ${width || "100%"};
  `};
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Unit = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${unitWidthInPx}px;
    color: ${theme.palette.neutral.medium};

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 0;
      height: 24px;
      width: 1px;
      background-color: ${theme.palette.neutral.medium};
    }
  `};
`;

const Icon = styled.div`
  cursor: pointer;
  ${({ theme }) => `
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${iconSizeInPx}px;
    color: ${theme.palette.neutral.medium};

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 0;
      height: 24px;
      width: 1px;
      background-color: ${theme.palette.neutral.medium};
    }
  `}
`;

const StyledInput = styled.input<IStyledInput>`
  ${({ theme, unit, icon, displayHints, transparent, bgColor }) => `
    background-color: ${
      transparent ? "transparent" : bgColor || theme.palette.neutral.white
    };
    display: block;
    width: 100%;
    height: 44px;
    padding: 0 12px;
    ${unit ? `padding-right: ${16 + unitWidthInPx}px` : ""};
    ${icon ? `padding-right: ${16 + iconSizeInPx + iconPositionRight}px` : ""};
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: ${theme.palette.text.primary};
    border: 1px solid ${theme.palette.neutral.lightGrey};
    border-radius: 10px;
    outline: none;

    &:focus {
      border-color: ${theme.palette.primary.main};

      + ${Unit}::before {
        background-color: ${theme.palette.primary.main};
      }

      + ${Icon} {
        color: ${theme.palette.primary.main};
      }
    }

    &:disabled {
      color ${rgba(theme.palette.text.primary, 0.4)};
      background-color: ${theme.palette.neutral.veryLight};

      + ${Unit} {
        color: ${rgba(theme.palette.text.primary, 0.4)};

        &::before {
          background-color: ${rgba(theme.palette.text.primary, 0.4)};
        }
      }

      + ${Icon} {
        color: ${rgba(theme.palette.text.primary, 0.4)};
      }
    }
  `};
`;

const Hints = styled.div`
  ${({ theme }) => `
    positon: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 250px;
    overflow: auto;
    border-top: none;
    background-color: ${theme.palette.neutral.veryLight};
  `};
`;

const Hint = styled.div`
  ${({ theme }) => `  
    height: 36px;
    overflow: auto;
    font-size: 13px;
    color: ${theme.palette.text.primary};
    border-top: none;
    border-bottom: none;
    outline: none;
    background-color: ${theme.palette.neutral.veryLight};
    padding: 0 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
        color: ${theme.palette.primary.main};
    }
    &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    };
  `}
`;

// eslint-disable-next-line react/display-name
export const TextField = React.forwardRef<HTMLInputElement, TextFieldType>(
  (
    {
      label,
      value,
      name,
      width,
      error,
      unit,
      icon,
      disabled,
      hints,
      onHintClick,
      transparent,
      bgColor,
      onIconClick,
      onChange,
      type,
      placeholder,
      hideErrorBox,
    },
    ref,
  ) => {
    const [displayHints, setDisplayHints] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const handleFocus = () => {
      setDisplayHints(true);
    };

    const handleHintClick = (hint: IHint) => {
      if (onHintClick) onHintClick(hint);
    };

    useOnClickOutside(wrapperRef, () => {
      setDisplayHints(false);
    });

    return (
      <Root width={width}>
        {label ? (
          <Label error={error} disabled={disabled}>
            {label}
          </Label>
        ) : null}
        <InputWrapper ref={wrapperRef}>
          <StyledInput
            ref={ref}
            type={type || "text"}
            name={name}
            error={error}
            unit={unit}
            icon={icon}
            disabled={disabled}
            onFocus={handleFocus}
            displayHints={displayHints}
            transparent={transparent}
            bgColor={bgColor}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
          />
          {unit && <Unit>{unit}</Unit>}
          {icon && <Icon onClick={onIconClick}>{icon}</Icon>}
          {displayHints && hints && hints.length !== 0 && (
            <Hints>
              {hints.map((hint) => (
                <Hint key={hint.value} onClick={() => handleHintClick(hint)}>
                  {hint.label}
                </Hint>
              ))}
            </Hints>
          )}
        </InputWrapper>
        {!hideErrorBox && <ErrorText show={!!error}>{error}</ErrorText>}
      </Root>
    );
  },
);
