const ArrowDown = ({ ...props }) => {
  return (
    <svg
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5_179)">
        <path
          d="M0.899902 1L4.4999 5L8.0999 1"
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_179">
          <rect
            width="6"
            height="9"
            rx="2"
            transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowDown;
