import { Flex } from "components";
import styled from "styled-components";
import { device } from "styles/theme";

export const Wrapper = styled(Flex)`
  border-radius: 10px;
  height: max-content;
  background-color: white;
`;

export const HeaderWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  align-items: baseline;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;
