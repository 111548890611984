const common = {
  privacyPolicy: "polityka prywatności",
  regulamin: "regulamin",
  first_name: "imię",
  last_name: "nazwisko",
};

const auth = {
  login: "zaloguj się",
  register: "zarejestruj się",
  password: "hasło",
  forgotPass: "Zapomniałeś hasła?",
  accountless: "Nie masz konta?",
  emailRequired: "Email jest wymagany",
  badEmailFormat: "Niepoprawny format email",
  passwordRequired: "Hasło jest wymagane",
  passMinVal: "Hasło powinno zawierać przynajmniej 6 znaków",
  passMaxVal: "Hasło może zawierać maksymalnie 18 znaków",
  passDiffErr: "Hasła nie są takie same",
  acceptRegulamin: "Zaakceptuj regulamin",
  password_confirmation: "potwierdzenie hasła",
};

const buttons = {
  downloadApp: "Pobierz aplikację mobilną",
  zoomIn: "Aa+",
  zoomOut: "Aa-",
  login: "Zaloguj się",
  register: "Zarejestruj się",
  logout: "Wyloguj się",
  sendEmail: "Wyślij e-mail",
};

export default {
  buttons,
  ...common,
  ...auth,
};
