import React, { useEffect, useState } from "react";
import { Flex, Box, Avatar, Dropdown } from "components";
import styled from "styled-components";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { Button } from "components/_form";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { ReactComponent as AkcentLogo } from "assets/icons/akcent-logo-new.svg";
import { logout } from "api/authApi";

export interface INavbar {
  logged: boolean;
}

const Wrapper = styled(Flex)`
  max-width: 1280px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
    width: 100%;
  `}
`;

export const Navbar: React.FC<INavbar> = ({ logged }) => {
  const { t, i18n } = useTranslation();
  const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [isZoomed, setIsZoomed] = useState(
    localStorage.getItem("isZoomed") === "1",
  );

  useEffect(() => {
    // @ts-ignore
    document.body.style.zoom = isZoomed ? 1.25 : 1;
    localStorage.setItem("isZoomed", isZoomed ? "1" : "0");
  }, [isZoomed]);

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await logout();
      localStorage.clear();

      navigate("/login");
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const profileDropdownItems = [
    { label: t("buttons.logout"), onClick: handleLogout },
  ];

  return (
    <Wrapper>
      <AkcentLogo
        style={{ flexShrink: "0", cursor: "pointer" }}
        onClick={() => navigate("/app")}
      />

      <Flex gap="10px">
        <Button
          variant="primary"
          bordered
          fitContent
          label={isZoomed ? t("buttons.zoomOut") : t("buttons.zoomIn")}
          onClick={() => setIsZoomed((prev) => !prev)}
        />
        {logged ? (
          <Dropdown
            items={profileDropdownItems}
            name={
              currentUser?.profile?.first_name +
              " " +
              currentUser?.profile?.last_name
            }
          >
            <Avatar border url="" />
          </Dropdown>
        ) : (
          <Flex gap="10px">
            <Button
              bordered
              type="submit"
              onClick={() => navigate("/register")}
              label={t("buttons.register")}
            />
            <Button
              type="submit"
              onClick={() => navigate("/login")}
              label={t("buttons.login")}
            />
          </Flex>
        )}
      </Flex>
    </Wrapper>
  );
};
