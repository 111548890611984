import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Flex, Modal, Box } from "components";
import { TextField, Button } from "components/_form";
import { H2 } from "components/Typography/Typography";

interface FormValues {
  item: string;
  count: number;
  min_count: number;
}

interface CreateStorageModalProps {
  isOpen: boolean;
  onCancelClick: () => void;
  onSubmit: (storageData: any) => void;
}

const CreateStorageModal: React.FC<CreateStorageModalProps> = ({
  isOpen,
  onCancelClick,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValues>();

  const onCancelClickAndReset = () => {
    reset();
    onCancelClick();
  };

  const onSubmitAndClose = async (storageData: any) => {
    await onSubmit(storageData);
    onCancelClick();
  };

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClickAndReset}>
      <form onSubmit={handleSubmit(onSubmitAndClose)}>
        <Flex flexDirection="column">
          <H2 variant="h2" mb={2}>
            Dodaj przedmiot
          </H2>
          <Box mt={2}>
            <Controller
              control={control}
              name="item"
              rules={{ required: "Przedmiot jest wymagany" }}
              render={({ field }) => (
                <TextField
                  transparent
                  {...field}
                  label="Przedmiot"
                  error={errors.item?.message}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="count"
              rules={{ required: "Liczba jest wymagana" }}
              render={({ field }) => (
                <TextField
                  transparent
                  {...field}
                  type="number"
                  label="Liczba"
                  error={errors.count?.message}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="min_count"
              rules={{ required: "Minimalna liczba jest wymagana" }}
              render={({ field }) => (
                <TextField
                  transparent
                  {...field}
                  type="number"
                  label="Minimalna liczba"
                  error={errors.min_count?.message}
                />
              )}
            />
          </Box>
          <Box mt={48} />
          <Button type="submit" variant="primary" label="Dodaj" />
          <Box mt={3} />
          <Button
            variant="secondary"
            label="Cofnij"
            onClick={onCancelClickAndReset}
          />
        </Flex>
      </form>
    </Modal>
  );
};

export default CreateStorageModal;
