import { http } from "utilities/http";
import { User, Users } from "types/user";
import { ME, PROFILE, USERS, USER } from "constants/paths";
import { serialize } from "object-to-formdata";
import qs from "qs";
import { UpdateProfile } from "types/forms/user";
import { PaginationFilter } from "types/common";

export const getMe = () =>
  http<{ data: User }>(ME, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

export const updateProfile = async (profile: UpdateProfile) => {
  const res = await http<{ data: User }>(PROFILE, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize(profile),
  });

  return res.data;
};

export const getUsers = async (filters?: PaginationFilter) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return await http<Users>(`${USERS}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getUser = async (id: string | number) => {
  return await http<{ data: User }>(USER(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
