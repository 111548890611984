import { Flex } from "components/Flex";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

interface ITabMenu {
  items: TItem[];
  active: number;
  setActive: Dispatch<SetStateAction<number>>;
}

type TItem = {
  id: number;
  label: string;
};

const Element = styled(Flex)<{ active: boolean }>`
  height: 100%;
  cursor: pointer;
  ${({ active, theme }) =>
    active &&
    `border-bottom: 1px solid ${theme.palette.primary.main}; color: ${theme.palette.primary.main};`}
`;

export const TabMenu = ({ items, active, setActive }: ITabMenu) => {
  return (
    <Flex height="100%" gap="20px">
      {items.map((el, k) => {
        return (
          <Element
            active={el.id === active}
            key={k}
            onClick={() => setActive(el.id)}
          >
            {el.label}
          </Element>
        );
      })}
    </Flex>
  );
};
