import { Box, Flex, Pagination } from "components";
import ButtonsBar from "../components/ButtonsBar";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TasksTable } from "../components/TasksTable";
import { Task, UpdateTaskRequest } from "types/task";
import { H4 } from "components/Typography/Typography";
import { GetTasksFilter } from "services/tasks";

interface DailyTasksProps {
  setFilterOptions: Dispatch<SetStateAction<GetTasksFilter>>;
  setModalTask: Dispatch<SetStateAction<Task | undefined>>;
  setIsModalDetailsOpen: Dispatch<SetStateAction<boolean>>;
  filterOptions: GetTasksFilter;
  tasks: Task[];
  totalPages: number;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  onUpdateClick: (task: UpdateTaskRequest, taskId: string) => void;
}

const DailyTasksView = ({
  setFilterOptions,
  setModalTask,
  setIsModalDetailsOpen,
  filterOptions,
  tasks,
  totalPages,
  page,
  setPage,
  onUpdateClick,
}: DailyTasksProps) => {
  return (
    <>
      <Flex flexDirection="column" width="100%" gap="20px">
        <Box px="40px">
          <ButtonsBar filter={filterOptions} setFilter={setFilterOptions} />
        </Box>

        <TasksTable
          tasks={tasks}
          setIsModalDetailsOpen={setIsModalDetailsOpen}
          setModalTask={setModalTask}
          onUpdateClick={onUpdateClick}
        />
        <Flex flexDirection="column" width="100&" p="40px">
          {!!tasks.length ? (
            <Pagination
              current={page}
              total={totalPages}
              setCurrent={setPage}
            />
          ) : (
            <H4 variant="h4" textAlign="center">
              Brak zaplanowanych zadań
            </H4>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default DailyTasksView;
