const UsersIcon = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.6005 7.93548C8.52805 7.93548 10.0906 6.38292 10.0906 4.46774C10.0906 2.55256 8.52805 1 6.6005 1C4.67294 1 3.11035 2.55256 3.11035 4.46774C3.11035 6.38292 4.67294 7.93548 6.6005 7.93548Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="0"
        strokeLinecap="round"
      />
      <path
        d="M11.4216 2.65537C11.9115 2.56954 12.4157 2.62073 12.878 2.8032C13.3403 2.98568 13.7424 3.29226 14.0395 3.6887C14.3365 4.08514 14.5168 4.55583 14.5602 5.04819C14.6035 5.54054 14.5083 6.03518 14.285 6.47685C14.0618 6.91852 13.7193 7.28982 13.2959 7.54929C12.8726 7.80875 12.3849 7.94616 11.8875 7.94616"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="0"
        strokeLinecap="round"
      />
      <path
        d="M1 13.5C1 12.7693 1.14486 12.0457 1.42631 11.3706C1.70776 10.6955 2.12028 10.0821 2.64033 9.56535C3.16038 9.04864 3.77777 8.63876 4.45725 8.35912C5.13672 8.07948 5.86498 7.93555 6.60044 7.93555C7.3359 7.93555 8.06416 8.07948 8.74364 8.35912C9.42312 8.63876 10.0405 9.04864 10.5606 9.56535C11.0806 10.0821 11.4931 10.6955 11.7746 11.3706C12.056 12.0457 12.2009 12.7693 12.2009 13.5"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="0"
        strokeLinecap="round"
      />
      <path
        d="M11.8877 7.94604C13.2832 8.06735 14.5823 8.70387 15.5287 9.73004C16.4751 10.7562 17 12.0975 17 13.4894"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="0"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default UsersIcon;
