import { http } from "utilities/http";
import { CLIENTS, CLIENTS_D } from "constants/paths";
import qs from "qs";
import { PaginationFilter } from "types/common";
import { ClientResponse } from "../types/clients";

export const getClients = async (filters?: PaginationFilter) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return await http<ClientResponse>(`${CLIENTS_D}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
