import { http } from "utilities/http";
import {
  ApartmentResponse,
  ReservationResponse,
  Apartment,
  Reservation,
} from "types/apartment";
import { APARTMENTS, RESERVATIONS, LOCKS } from "constants/paths";
import qs from "qs";
import { PaginationFilter } from "types/common";

export const getApartments = async (filters: PaginationFilter) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return await http<ApartmentResponse>(`${APARTMENTS}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getApartment = async (ido_booking_identifier: string) => {
  return await http<Apartment>(`${APARTMENTS}/${ido_booking_identifier}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getReservations = async (
  filters: PaginationFilter,
  apartment_id: string,
) => {
  const query = qs.stringify(
    { ...filters, apartment_id },
    { arrayFormat: "brackets" },
  );
  return await http<ReservationResponse>(`${RESERVATIONS}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getReservation = async (ido_booking_identifier: string) => {
  return await http<Reservation>(`${RESERVATIONS}/${ido_booking_identifier}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const generateApartmentCode = async (lockId: number | string, params: {
  "valid_from": string // "23.02.2023",
  "valid_to": string // "24.02.2023"
}) => {
  return await http(`${LOCKS}/${lockId}/generate_new_code`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ ...params }),
  });
};

export const sendApartmentCode = async (lockId: number | string, params: {user_id: string | number}) => {
  return await http(`${LOCKS}/${lockId}/send_code_to_client`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ ...params }),
  });
};
