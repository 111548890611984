import React, { useRef } from "react";
import styled from "styled-components";
import { P } from "components/Typography/Typography";
import { Task, UpdateTaskRequest } from "types/task";
import { format } from "date-fns";
import { Status } from "components/Legend/Legend";
import { Button } from "components/_form";
import { ReactComponent as ArrowRight } from "assets/icons/ico-arrow-right.svg";
import { Flex } from "components";
import Images from "./Images";
import AddIcon from "components/icons/AddIcon";

const Tr = styled.tr<{ color: string }>`
  background-color: ${({ color }) => color};
`;

const Td = styled.td`
  padding: 10px;
  border-bottom: 1px solid #dadce0;
  max-width: 250px;
`;

const DetailsButton = styled(Flex)`
  cursor: pointer;
  height: 28px;
  width: 28px;
  border-radius: 28px;
  justify-content: center;
  align-items: center;
  border: 1px solid #dadce0;
  background-color: #f4f6f6;
  :hover,
  :active {
    background-color: #a0a0a0;
  }
`;

interface TaskTileProps {
  setModalTask: React.Dispatch<React.SetStateAction<Task | undefined>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  task: Task;
  onUpdateClick: (task: UpdateTaskRequest, taskId: string) => void;
}

const TaskTile: React.FC<TaskTileProps> = ({
  task,
  setModalTask,
  setIsModalOpen,
  onUpdateClick,
}) => {
  const {
    planned_date_from,
    status,
    apartment,
    activity,
    user,
    photos_urls,
    previous_reservation_for_apartment,
    id,
  } = task;
  const formatDate = "HH:mm dd.MM.yyyy";
  const onlyDayFormat = "dd.MM.yyyy";
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const formattedServiceDate = format(
    new Date(planned_date_from),
    onlyDayFormat,
  );

  const formattedCheckIn = previous_reservation_for_apartment?.date_from
    ? format(new Date(previous_reservation_for_apartment.date_from), formatDate)
    : "";

  const formattedCheckOut = previous_reservation_for_apartment?.date_to
    ? format(new Date(previous_reservation_for_apartment.date_to), formatDate)
    : "";

  const handleEditClick = () => {
    setModalTask(task);
    setIsModalOpen(true);
  };

  const handleAddPhoto = async (images: File[]) => {
    onUpdateClick({ task: { photos: images } }, String(id));
  };

  return (
    <Tr color={apartment.location?.color ?? "white"}>
      <Td>
        <P variant="body2">{apartment?.short_name}</P>
      </Td>
      <Td>
        <P variant="body2">
          {user?.profile?.first_name} {user?.profile?.last_name}
        </P>
      </Td>
      <Td>
        <P variant="body2">{activity?.name}</P>
      </Td>
      <Td>
        <P variant="body2">{formattedCheckIn}</P>
      </Td>
      <Td>
        <P variant="body2">{formattedCheckOut}</P>
      </Td>
      <Td>
        <P variant="body2">{formattedServiceDate}</P>
      </Td>
      <Td>
        <Status value={status} />
      </Td>
      <Td>
        {photos_urls && !!photos_urls.length ? (
          <Images images={photos_urls} />
        ) : (
          <Button
            label="Załącz obraz"
            onClick={(event) => {
              event.preventDefault();
              fileInputRef.current?.click();
            }}
            icon={<AddIcon />}
          />
        )}
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          multiple
          onChange={(event) => {
            if (event.target.files) {
              handleAddPhoto(Array.from(event.target.files));
            }
          }}
        />
      </Td>
      <Td>
        <DetailsButton onClick={handleEditClick}>
          <ArrowRight />
        </DetailsButton>
      </Td>
    </Tr>
  );
};

export default TaskTile;
