import React, { useEffect, useMemo, useState } from "react";
import { H3 } from "components/Typography/Typography";
import { Wrapper } from "./ClientsView.styled";
import { getClients } from "../../services/clients";
import { Client } from "../../types/clients";
import { useTable, useSortBy, useExpanded } from "react-table";
import { Table } from "../../components/Table";
import { Flex, Pagination } from "components";
import ArrowDownIcon from "components/icons/ArrowDown";
import ArrowUpIcon from "components/icons/ArrowUp";

export const ClientsView = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);

  const fetchClients = () => {
    getClients({ per_page: 10, page }).then((res) => {
      setClients(
        res.data.map((client) => {
          return {
            ...client,
            subRows: client.all_reservations_by_phone_number,
          };
        }),
      );
      setTotal(res.pagination?.pages ?? 0);
    });
  };

  useEffect(() => {
    fetchClients();
  }, [page]);

  const columns = useMemo(
    () => [
      {
        Header: "ID:",
        accessor: "id",
      },
      {
        Header: "Numer telefonu:",
        accessor: "phone_number",
      },
      {
        Header: "Imię:",
        accessor: "first_name",
      },
      {
        Header: "Nazwisko:",
        accessor: "last_name",
      },
      {
        Header: "Email:",
        accessor: "email",
      },
      {
        Header: "Ilość rezerwacji:",
        accessor: "reservations_count",
      },
      {
        id: "expander",
        Header: "",
        // @ts-ignore
        Cell: ({ row }) => {
          return row.canExpand ? (
            <Flex justifyContent="center" {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </Flex>
          ) : null;
        },
      },
    ],
    [],
  );

  const tableInstance = useTable(
    // @ts-ignore
    { columns, data: clients },
    useSortBy,
    useExpanded,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = tableInstance;

  return (
    <Wrapper flexDirection="column" gap="20px">
      <Flex flexDirection="column" p="40px 40px 0px 40px">
        <H3 variant="h3">Klienci</H3>
      </Flex>
      <Table
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        getTableBodyProps={getTableBodyProps}
      />
      <Flex flexDirection="column" pb="20px">
        <Pagination current={page} total={total} setCurrent={setPage} />
      </Flex>
    </Wrapper>
  );
};

export default ClientsView;
