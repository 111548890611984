import React from "react";
import { Footer } from "layouts/components/Footer";
import {
  FooterContent,
  NavbartContent,
  ViewContent,
  GuestWrapper,
  AuthFlex,
} from "./Layout.styled";
import { Navbar } from "./components/Navbar";

export const GuestLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isLoggedIn = false;

  return (
    <GuestWrapper>
      <NavbartContent>
        <Navbar logged={isLoggedIn} />
      </NavbartContent>
      <ViewContent>
        <AuthFlex>{children}</AuthFlex>
      </ViewContent>
      <FooterContent>
        <Footer />
      </FooterContent>
    </GuestWrapper>
  );
};
