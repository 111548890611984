import React from "react";
import styled from "styled-components";
import { Task, UpdateTaskRequest } from "types/task";
import TaskTile from "./TaskTile";

const Th = styled.th`
  border-bottom: 1px solid #a0a0a0;
  color: #a0a0a0;
  font-weight: normal;
  padding: 10px;
  text-align: left;
  font-size: 14px;
`;

interface ITasksTable {
  tasks: Task[];
  setModalTask: React.Dispatch<React.SetStateAction<Task | undefined>>;
  setIsModalDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdateClick: (task: UpdateTaskRequest, taskId: string) => void;
}

export const TasksTable = ({
  tasks,
  setModalTask,
  setIsModalDetailsOpen,
  onUpdateClick,
}: ITasksTable) => {
  return (
    <table cellSpacing={0}>
      <thead>
        <tr>
          <Th>Pokój:</Th>
          <Th>Pracownik:</Th>
          <Th>Rodzaj zadania:</Th>
          <Th>Check in:</Th>
          <Th>Check out:</Th>
          <Th>Data usługi:</Th>
          <Th>Status:</Th>
          <Th>Obraz:</Th>
          <Th></Th>
        </tr>
      </thead>
      <tbody>
        {tasks?.map((task, index) => (
          <TaskTile
            key={index}
            task={task}
            setIsModalOpen={setIsModalDetailsOpen}
            setModalTask={setModalTask}
            onUpdateClick={onUpdateClick}
          />
        ))}
      </tbody>
    </table>
  );
};
