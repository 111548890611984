import { FC, useState } from "react";
import { Box, Flex, Spinner } from "components";
import { Button, TextField } from "components/_form";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordSchema } from "validation/authSchemas";
import { IResetPassword } from "types/forms/auth";
import { resetPassword } from "../api/authApi";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { H3, P } from "components/Typography/Typography";

const Wrapper = styled(Flex)`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ResetPasswordView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<IResetPassword>({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(resetPasswordSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    try {
      const response = await resetPassword(data);
      if (!response) return;
      navigate("/app");
    } catch (err: unknown) {
      // @ts-ignore
      if (err && err.message) toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Wrapper
      justifyContent="center"
      flexDirection="column"
      height="100%"
      gap="30px"
    >
      <H3 variant="h3" textAlign="center">
        Przypomnienie hasła
      </H3>
      <P variant="body2" textAlign="center">
        Wpisz adres e-mail powiązany z kontem, a my wyślemy Ci link potrzebny do
        zresetowania hasła.
      </P>
      <Form onSubmit={onSubmit}>
        <TextField
          label="Email:"
          {...register("email")}
          error={t(errors.email?.message || "")}
        />

        {isLoading ? (
          <Spinner />
        ) : (
          <Button
            fullWidth
            type="submit"
            disabled={!formState.isValid}
            label={t("buttons.sendEmail")}
          />
        )}
      </Form>
    </Wrapper>
  );
};
