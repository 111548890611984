import React from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import styled from "styled-components";

export interface ILink {
  to: LinkProps["to"];
  target?: string;
  children?: React.ReactNode;
}

const StyledLink = styled(RouterLink)`
  text-decoration: underline;
  font-size: 14px;
  ${({ theme }) => `
    color: ${theme.palette.text.primary};
    display: block;
  `};
`;

const Link: React.FC<ILink> = ({ children, to, target }) => (
  <StyledLink target={target} to={to}>
    {children}
  </StyledLink>
);

export { Link };
