import { Flex } from "components/Flex";
import styled, { CSSProperties } from "styled-components";

interface IDot {
  color?: CSSProperties["backgroundColor"];
  small?: boolean;
}

const Wrapper = styled(Flex)`
  flex-flow: column nowrap;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
`;

const Circle = styled(Flex)<IDot>`
  flex-shrink: 0;
  width: ${({ small }) => (small ? "16px" : "22px")};
  aspect-ratio: 1/1;
  border-radius: 100px;
  border: 1px solid ${({ color }) => color};
  justify-content: center;
  align-items: center;
`;

const Dot = styled(Flex)<IDot>`
  width: ${({ small }) => (small ? "10px" : "14px")};
  aspect-ratio: 1/1;
  border-radius: 100px;
  background-color: ${({ color }) => color};
`;

const Label = styled.span`
  font-size: 14px;
`;

interface IStatus {
  label: string;
  value: "clear" | "pending" | "dirty" | "canceled";
  color: CSSProperties["backgroundColor"];
  small?: boolean;
}

export const status: IStatus[] = [
  { label: "Posprzątane", value: "clear", color: "#37BF66" },
  { label: "Do posprzątania", value: "dirty", color: "#E42352" },
  { label: "W trakcie sprzątanie", value: "pending", color: "#F1BF3F" },
  { label: "Anulowane", value: "canceled", color: "#Bababa" },
];

export const Legend = () => {
  return (
    <Wrapper>
      <Label>Legenda:</Label>
      <Flex flexDirection="column" width="100%" gap="10px">
        {status.map((el, k) => {
          return (
            <Flex key={k} alignItems="center" gap="5px">
              <Circle color={el.color}>
                <Dot color={el.color} />
              </Circle>
              <Label>{el.label}</Label>
            </Flex>
          );
        })}
      </Flex>
    </Wrapper>
  );
};

export const Status = ({ value, small }: Pick<IStatus, "value" | "small">) => {
  const result = status.find((e) => e.value === value);

  return (
    <Circle color={result?.color} small={small}>
      <Dot color={result?.color} small={small} />
    </Circle>
  );
};
