import React, { useEffect, useState } from "react";
import { Comments, Grid, SeparatorStyled } from "./TaskDetailsModal";
import { Flex } from "components";
import CalendarIcon from "components/icons/CalendarIcon";
import { theme } from "styles";
import { P } from "components/Typography/Typography";
import { Reservation } from "types/apartment";
import { format } from "date-fns";
import { dynamicDateFormat } from "utilities/dates";
import { getReservations } from "services/apartments";

interface ILastAndComingReservationsProps {
  apartmentId?: number;
}

const LastAndComingReservations = ({
  apartmentId,
}: ILastAndComingReservationsProps) => {
  const [reservations, setReservations] = useState<Reservation[]>([]);

  const fetchReservations = async (apartmentId: number) => {
    try {
      const response = await getReservations({}, apartmentId.toString());
      const now = new Date();
      const sortedReservations = response.data.sort(
        (a, b) => new Date(b.date_to).getTime() - new Date(a.date_to).getTime(),
      );
      const lastReservation = sortedReservations.find(
        (reservation) => new Date(reservation.date_to) < now,
      );
      const upcomingReservation = sortedReservations.find(
        (reservation) => new Date(reservation.date_from) > now,
      );

      setReservations(
        [lastReservation, upcomingReservation].filter(Boolean) as Reservation[],
      );
    } catch (error) {
      console.error("Failed to fetch reservations", error);
    }
  };

  useEffect(() => {
    if (apartmentId) fetchReservations(apartmentId);
  }, [apartmentId]);

  return apartmentId && reservations.length ? (
    reservations.map((reservation, index) => (
      <React.Fragment key={index}>
        {index === 0 && (
          <>
            <SeparatorStyled />
            <Grid>
              <Flex gap="5px" alignItems="center">
                <CalendarIcon color={theme.palette.primary.main} />
                <P variant="body2" fontWeight={600} color="dark">
                  Ostatnia rezerwacja:
                </P>
              </Flex>

              <P variant="body2" color="dark">
                {dynamicDateFormat(
                  new Date(reservation.date_from),
                  new Date(reservation.date_to),
                )}
              </P>

              <P variant="body2" fontWeight={600}>
                Wyjazd:
              </P>
              <P variant="body2">
                {format(new Date(reservation.date_to), "dd.MM.yyyy', 'HH:mm")}
              </P>

              <P variant="body2" fontWeight={600}>
                Liczba osób:
              </P>
              <P variant="body2">{reservation.number_of_people}</P>
              <P variant="body2" fontWeight={600}>
                Uwagi klienta:
              </P>
            </Grid>
            <Comments>{reservation.note ? reservation.note : "Brak"}</Comments>
          </>
        )}
        {index === 1 && (
          <>
            <SeparatorStyled />
            <Grid>
              <Flex gap="5px" alignItems="center">
                <CalendarIcon color={theme.palette.primary.main} />
                <P variant="body2" fontWeight={700} mr={2} color="dark">
                  Nadchodząca rezerwacja:
                </P>
              </Flex>
              <P variant="body2" color="dark">
                {dynamicDateFormat(
                  new Date(reservation.date_from),
                  new Date(reservation.date_to),
                )}
              </P>

              <P variant="body2" fontWeight={700} mr={2}>
                Przyjazd:
              </P>
              <P variant="body2">
                {format(new Date(reservation.date_from), "dd.MM.yyyy', 'HH:mm")}
              </P>

              <P variant="body2" fontWeight={700} mr={2}>
                Liczba osób:
              </P>
              <P variant="body2">{reservation.number_of_people}</P>
            </Grid>
          </>
        )}
      </React.Fragment>
    ))
  ) : (
    <></>
  );
};

export default LastAndComingReservations;
