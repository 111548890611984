import styled, { createGlobalStyle } from "styled-components";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { rgba } from "utilities/rgba";
import { Flex } from "components";
import { pl } from "date-fns/locale";

interface CustomDatePickerProps extends Omit<ReactDatePickerProps, "value"> {
  value: Date;
  onChange: (...event: any[]) => void;
  error?: string;
  id?: string;
  rangePicker?: boolean;
}

const DatePickerWrapperStyles = createGlobalStyle`
    .date_picker.full-width {
        width: 100%;
    }
    .react-datepicker-popper {
  z-index: 3;
}
`;

export const StyledDatePicker = styled(DatePicker)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
    color: ${theme.palette.text.primary};
    border: 1px solid ${theme.palette.neutral.medium};

    &:focus {
      border-color: ${theme.palette.primary.main};
    }

    &:disabled {
      color ${rgba(theme.palette.text.primary, 0.4)};
      background-color: ${theme.palette.neutral.veryLight};
    }
    border: 1px solid ${theme.palette.neutral.lightGrey};
  `}
  padding: 0px 16px;
  width: 100%;
  height: 44px;
  border-radius: 8px;
`;

export const CustomDatePicker = ({
  value,
  onChange,
  id,
  rangePicker,
  ...props
}: CustomDatePickerProps) => (
  <>
    <Flex width="100%">
      <StyledDatePicker
        wrapperClassName="date_picker full-width"
        locale={pl}
        selected={value ? new Date(value) : undefined}
        onChange={(date) => {
          if (rangePicker) onChange(date);
          else onChange(date instanceof Date ? date : null);
        }}
        dateFormat="dd-MM-yyyy"
        id={id}
        selectsRange={rangePicker}
        {...props}
      />
      <DatePickerWrapperStyles />
    </Flex>
  </>
);
