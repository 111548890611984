import React from "react";
import { Navbar } from "layouts/components/Navbar";
import {
  GridSidebar,
  AppWrapper,
  NavbartContent,
  ViewContent,
} from "./Layout.styled";
import { SideBar } from "./components/Sidebar";

export const AppLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isLoggedIn = true;

  return (
    <AppWrapper>
      <NavbartContent>
        <Navbar logged={isLoggedIn} />
      </NavbartContent>
      <ViewContent>
        <GridSidebar>
          <SideBar />
          {children}
        </GridSidebar>
      </ViewContent>
    </AppWrapper>
  );
};
