import { Flex } from "components";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  border-radius: 10px;
  height: max-content;
  background-color: white;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  width: 100%;
`;

export const LabelRow = styled(Grid)`
  margin-bottom: 20px;
  margin-left: 5px;
  padding: 0 32px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  ${({ theme }) => `
    color: ${theme.palette.neutral.lightGrey};
  `};
`;
