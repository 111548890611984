import { Flex } from "components/Flex";
import styled from "styled-components";
import { rgba } from "utilities/rgba";

interface ISidebarMenu {
  isActive: boolean;
}

const SidebarMenuItem = styled(Flex)<ISidebarMenu>`
  gap: 10px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 14px;
  width: 100%;
  padding: 10px 20px;
  background-color: #fff;
  color: ${({ theme }) => theme.palette.text.primary};
  svg path {
    stroke: ${({ theme }) => theme.palette.text.primary};
  }
  :hover {
    ${({ theme, isActive }) =>
      !isActive && `background-color: ${rgba(theme.palette.primary.main, 0.2)}`}
  }
  ${({ isActive, theme }) =>
    isActive &&
    `background-color: ${theme.palette.primary.main};
    color:${theme.palette.neutral.white};
    svg path {
      stroke: ${theme.palette.neutral.white};
  }`};
`;

export default SidebarMenuItem;
