import React from "react";
import styled from "styled-components";
import { Button } from "components/_form";
import CreateStorageModal from "./CreateStorageModal";
import { CreateStorageRequest } from "types/storage";
import { Flex } from "components";

const ButtonsBarWrapper = styled(Flex)`
  width: 100%;
  height: 48px;
  margin-bottom: 64px;
  margin-top: 40px;
`;

interface ButtonsBarProps {
  onAddStorage: (storageData: CreateStorageRequest) => void;
}

const ButtonsBar: React.FC<ButtonsBarProps> = ({ onAddStorage }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      <ButtonsBarWrapper>
        <Button
          fullWidth
          variant="primary"
          label="Dodaj przedmiot"
          onClick={() => setIsModalOpen(true)}
        />
      </ButtonsBarWrapper>
      <CreateStorageModal
        key={isModalOpen.toString()}
        isOpen={isModalOpen}
        onCancelClick={() => setIsModalOpen(false)}
        onSubmit={onAddStorage}
      />
    </>
  );
};

export default ButtonsBar;
