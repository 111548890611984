import { rgba } from "utilities/rgba";

export const device = {
  mobile: `(max-width: 576px)`,
  tablet: `(max-width: 768px)`,
  laptop: `(max-width: 992px)`,
  desktop: `(max-width: 1200px)`,
};

const palettePrimaryMain = "#3E49C7";

export const theme = {
  palette: {
    primary: {
      main: palettePrimaryMain,
      dark: "#a26e2a",
    },
    neutral: {
      mainBlue: "#499CDD",
      dark: "#283038",
      grey: "#6A7482",
      lightGrey: "#a0a0a0",
      lightBlue: "#DFF2FB",
      light: "#EFEFEF",
      white: "#FFFFFF",
      veryLight: "#F6F6F6",
      medium: "#BEC9D5",
      green: "#4AAC3A",
      yellow: "#F1BF3F",
      tuna: "#E26986",
      anotherGrey: "#e3e3e3",
    },
    accent: {
      yellow: "#F5D73F",
      orange: "#F5843F",
      lightBlue: "#26B9E7",
      blue: "#76b7e5",
      red: "#FB5C00",
      green: "#D09B34",
      lightGreen: "#FFF4DE",
    },
    text: {
      primary: "#282932",
      secondary: rgba("##283038", 0.7),
    },
    background: "#F4F6F6",
  },
  textStyles: {
    body2: {
      fontSize: 14,
      lineHeight: "21px",
    },
    body: {
      fontSize: 18,
      lineHeight: "24px",
    },
    h4: {
      fontSize: 12,
      lineHeight: "20px",
      fontWeight: 700,
    },
    h3: {
      fontSize: 21,
      lineHeight: "21px",
      fontWeight: 700,
    },
    h2: {
      fontSize: 32,
      lineHeight: "36px",
      fontWeight: 700,
    },
    h1: {
      fontSize: 40,
      lineHeight: "44px",
      fontWeight: 700,
    },
  },
};
