import { ProtectedRoute } from "components/ProtectedRoute/ProtectedRoute";
import { AppLayout } from "layouts/AppLayout";
import { GuestLayout } from "layouts/GuestLayout";
import { Outlet, RouteObject } from "react-router-dom";
import { ExampleView } from "views/ExampleView";
import { RegisterView } from "./views/RegisterView";
import { LoginView } from "./views/LoginView";
import { ResetPasswordView } from "./views/ResetPasswordView";
import { TasksView } from "views/TasksView/TasksView";
import StoragesView from "views/StorageView/StorageView";
import ReservationsView from "views/ReservationsView/ReservationsView";
import ClientsView from "./views/ClientsView/ClientsView";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <ProtectedRoute isAuth>
        <GuestLayout>
          <Outlet />
        </GuestLayout>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/login",
        element: <LoginView />,
      },
      {
        path: "/register",
        element: <RegisterView />,
      },
      {
        path: "/password-reset",
        element: <ResetPasswordView />,
      },
    ],
  },
  {
    path: "/app",
    element: (
      <ProtectedRoute>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "example",
        element: <ExampleView />,
      },
      {
        path: "tasks",
        element: <TasksView />,
      },
      {
        path: "storage",
        element: <StoragesView />,
      },
      {
        path: "reservations",
        element: <ReservationsView />,
      },
      {
        path: "clients",
        element: <ClientsView />,
      },
    ],
  },
];
