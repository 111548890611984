const CalendarIcon = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 4.55561C1 4.08411 1.21071 3.63193 1.58579 3.29853C1.96086 2.96513 2.46957 2.77783 3 2.77783H15C15.5304 2.77783 16.0391 2.96513 16.4142 3.29853C16.7893 3.63193 17 4.08411 17 4.55561V15.2223C17 15.6938 16.7893 16.146 16.4142 16.4794C16.0391 16.8128 15.5304 17.0001 15 17.0001H3C2.46957 17.0001 1.96086 16.8128 1.58579 16.4794C1.21071 16.146 1 15.6938 1 15.2223V4.55561Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 1V4.55556"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 1V4.55556"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 8.11108H17"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;
