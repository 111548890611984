import React, { useEffect, useState } from "react";
import { H3, P } from "components/Typography/Typography";
import { Flex } from "components";
import { Apartment, Reservation } from "types/apartment";
import { LabelRow, Wrapper } from "./ReservationsView.styled";
import { getApartments, getReservations } from "services/apartments";
import ReservationsTile from "./components/ReservationsTile";
import ReservationsDetailsModal from "./components/ReservationsDetailsModal";

export const ReservationsView = () => {
  const [apartments, setApartments] = useState<Apartment[]>([]);
  const [reservations, setReservations] = useState<Reservation[][]>([]);
  const [selectedApartment, setSelectedApartment] = useState<Apartment | null>(
    null,
  );

  const handleDetailsClick = (id: string) => {
    const selectedApartment = apartments.find(
      (apartment) => apartment.id.toString() == id,
    );
    if (selectedApartment) {
      setSelectedApartment(selectedApartment);
    }
  };

  const handleModalClose = () => {
    setSelectedApartment(null);
  };

  const fetchApartmentsAndReservations = async () => {
    try {
      const apartmentsResponse = await getApartments({});
      setApartments(apartmentsResponse.data);

      const reservationsPromises = apartmentsResponse.data.map(
        async (apartment) => {
          const response = await getReservations({}, apartment.id.toString());
          const now = new Date();
          const sortedReservations = response.data.sort(
            (a, b) =>
              new Date(b.date_to).getTime() - new Date(a.date_to).getTime(),
          );
          const lastReservation = sortedReservations.find(
            (reservation) => new Date(reservation.date_to) < now,
          );
          const upcomingReservation = sortedReservations.find(
            (reservation) => new Date(reservation.date_from) > now,
          );

          return [lastReservation, upcomingReservation].filter(
            Boolean,
          ) as Reservation[];
        },
      );

      const reservationsData = await Promise.all(reservationsPromises);
      setReservations(reservationsData);
    } catch (error) {
      console.error("Nie udało się pobrać danych", error);
    }
  };

  useEffect(() => {
    fetchApartmentsAndReservations();
  }, []);

  return (
    <Wrapper flexDirection="column" gap="20px">
      <H3 variant="h3">Rezerwacje apartamentów</H3>
      <Flex flexDirection="column" gap="10px">
        <LabelRow>
          <P variant="body2" color="grey">
            Nazwa apartamentu
          </P>
          <P variant="body2" color="grey">
            Data zak. ostatniej rezerwacji
          </P>
          <P variant="body2" color="grey">
             Dat rozp. przyszłej rezerwacji
          </P>
          <P variant="body2" color="grey">
            Kod do zamku
          </P>
        </LabelRow>
        {apartments.map((apartment, index) => {
          const lastReservation = reservations[index]?.[0];
          const futureReservation = reservations[index]?.[1];

          return (
            <ReservationsTile
              key={apartment.id}
              apartment={apartment}
              lastReservation={lastReservation}
              futureReservation={futureReservation}
              onDetailsClick={handleDetailsClick}
            />
          );
        })}
      </Flex>
      <ReservationsDetailsModal
        isOpen={!!selectedApartment}
        apartment={selectedApartment || ({} as Apartment)}
        onCancelClick={handleModalClose}
        refetchData={fetchApartmentsAndReservations}
      />
    </Wrapper>
  );
};

export default ReservationsView;
