import { Flex } from "components/Flex";
// import { Tooltip } from "components/Tooltip";
import { theme } from "styles/theme";
import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { rgba } from "utilities/rgba";
import { Box } from "components/Box";

export interface ButtonI extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "blue" | "grey" | "lightBlue";
  disabled?: boolean;
  label?: string;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  bordered?: boolean;
  tooltipContent?: string;
  fitContent?: boolean;
}

interface StyledButtonI {
  variant: NonNullable<ButtonI["variant"]>;
  icon?: ButtonI["icon"];
  fullWidth?: ButtonI["fullWidth"];
  bordered?: ButtonI["bordered"];
  fontWeight?: ButtonI["fontWeight"];
  fitContent?: ButtonI["fitContent"];
}

const { palette } = theme;

const colorVariantsMap = {
  primary: palette.neutral.white,
  secondary: palette.neutral.white,
  blue: palette.neutral.white,
  grey: palette.text.primary,
  lightBlue: palette.neutral.mainBlue,
};

const backgroundColorVariantsMap = {
  primary: palette.primary.main,
  secondary: palette.neutral.dark,
  blue: palette.neutral.mainBlue,
  grey: palette.neutral.light,
  lightBlue: palette.neutral.lightBlue,
};

const backgroundColorHoverVariantsMap = {
  primary: rgba(palette.primary.main, 0.8),
  secondary: rgba(palette.neutral.dark, 0.8),
  blue: palette.accent.lightBlue,
  grey: palette.neutral.veryLight,
  lightBlue: palette.accent.blue,
};

const backgroundColorDisabledVariantsMap = {
  primary: palette.neutral.medium,
  secondary: palette.neutral.veryLight,
  blue: palette.neutral.medium,
  grey: palette.neutral.veryLight,
  lightBlue: palette.neutral.veryLight,
};

const StyledButton = styled.button<StyledButtonI>`
  ${({ variant, fullWidth, bordered, theme, fitContent }) => `
    display: ${fullWidth ? "flex" : "inline-flex"};
    align-items: center;
    justify-content: center;    
    gap: 5px;
    ${
      fullWidth
        ? `width: 100%;`
        : !fitContent
        ? "width: fit-content;"
        : "min-width: 120px;"
    };
    min-height: 44px;
    border-radius: 10px;    
    font-weight: 500;
    font-size: 14px;
    font-family: Inter, sans-serif;
    color: ${colorVariantsMap[variant]};
    background-color: ${backgroundColorVariantsMap[variant]};
    ${
      bordered
        ? `
          box-shadow: inset 0px 0px 0px 1px ${backgroundColorVariantsMap[variant]}; 
          color: ${backgroundColorVariantsMap[variant]};
          background-color: ${theme.palette.neutral.white};
          border: 0;
        `
        : "border: 0;"
    }
    outline: none;
    cursor: pointer;
    padding: 4px 22px;

    &:hover {
      background-color: ${backgroundColorHoverVariantsMap[variant]};
      ${
        bordered
          ? `
          box-shadow: inset 0px 0px 0px 1px ${
            backgroundColorHoverVariantsMap[variant]
          };
          color: ${backgroundColorHoverVariantsMap[variant]};
          background-color: ${rgba(backgroundColorVariantsMap[variant], 0.1)};
          border: 0;
          color: 
          border: 0;
        `
          : "border: 0;"
      }
    }

    &:disabled {
      pointer-events: none;
      background-color: ${backgroundColorDisabledVariantsMap[variant]};
    }
  `}
`;

export const Button: React.FC<ButtonI> = ({
  label,
  icon,
  bordered,
  variant = "primary",
  type = "button",
  disabled = false,
  fullWidth,
  onClick,
  children,
  fontWeight,
  fitContent,
  tooltipContent,
}) => (
  // <Tooltip fullWidth={fullWidth} content={tooltipContent}>
  <StyledButton
    type={type}
    icon={icon}
    variant={variant}
    disabled={disabled}
    fullWidth={fullWidth}
    bordered={bordered}
    onClick={onClick}
    fontWeight={fontWeight}
  >
    {children && (
      <Flex justifyContent="center" alignItems="center" mx={1}>
        {children}
      </Flex>
    )}
    <Box flexShrink={0}>{icon}</Box>

    {label}
  </StyledButton>
  // </Tooltip>
);
