import { Flex } from "components/Flex";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarMenuItem from "./SidebarMenuItem";

interface ISidebarMenu {
  items: TSidebarMenuItem[];
}

export type TSidebarMenuItem = {
  path: string;
  icon: React.ReactElement;
  label: string;
};

export const SidebarMenu = ({ items }: ISidebarMenu) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Flex gap="10px" flexDirection="column" width="100%">
      {items.map((e, k) => {
        return (
          <SidebarMenuItem
            key={k}
            isActive={e.path === pathname}
            onClick={() => {
              navigate(e.path);
            }}
          >
            {e.icon}
            {e.label}
          </SidebarMenuItem>
        );
      })}
    </Flex>
  );
};
